// Authoriations server
// On load checks for a session token
// if a sesiontoken exists get the loggedinauthz/loggedoutauthz observables
//
// exposes function updateFragment ... if the fragment is updated, 
//   1. generate a new key
//   2. request a certificate
//   3. add the certificate to the agent
//  4. update the loggedin/loggedout data
//
// exposes function refresh() ... if called, recalculates loggedin/loggedout
//
// exposes storeSshAuthZServer ... if called adds a new entry to the possible loggedin/loggedout and stores in localStorage
//
// maintains behavioursubject loggedinauthz and loggedin (list and count of sites that we have logged in to/have a valid cert in the agent for)
// maintains behavioursubject loggedoutauthz and loggedout (list and count of sites that we could log in to)


import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject, concat, forkJoin, ReplaySubject, merge } from 'rxjs';
import { catchError, map, tap, take,filter,skip, switchMap, shareReplay } from 'rxjs/operators';
import {LocationStrategy, Location} from '@angular/common';
import { Identity, AuthToken, KeyCert, SshAuthzServer } from './identity';
import { APIServer } from './apiserver';
import {BackendSelectionService} from './backend-selection.service';
import { throwError, of, combineLatest, from } from 'rxjs';
import {NotificationsService} from './notifications.service';
import { IpcService } from './ipc.service';
import * as jwktossh from "jwk-to-ssh";
import { certParamsClass, signingRequestClass, accessTokenClass } from './certParams';
import { OAuthService } from 'angular-oauth2-oidc';


export class SshKeyPair {
  private: string;
  public: string;
}

export class SshauthzServer {}

@Injectable({
  providedIn: 'root'
})

export class AuthorisationService {


  public loggedInAuthZ$: BehaviorSubject<SshAuthzServer[]>;
  public loggedOutAuthZ$: BehaviorSubject<SshAuthzServer[]>;
  public loggedin$: BehaviorSubject<number>;
  public loggedout$: BehaviorSubject<number>;
  public sessionToken$: BehaviorSubject<string|null> = new BehaviorSubject(null);
  public availableKeys$: BehaviorSubject<any>;

  private backendURI: string;
  private fragment$: BehaviorSubject<string> = new BehaviorSubject(null);
  private sshAuthzServers$: BehaviorSubject<SshAuthzServer[]>;
  //private refresh$: Subject<boolean> = new Subject();





  constructor(private http: HttpClient,
              private router: Router, 
              private backendSelectionService: BackendSelectionService,
              private notifications: NotificationsService,
              private ipcService: IpcService,
              private oauthService: OAuthService,
            ) {

    this.backendURI = null;
    this.sshAuthzServers$ = new BehaviorSubject<SshAuthzServer[]>(null);
    this.loggedin$= new BehaviorSubject<number>(null);
    this.loggedout$= new BehaviorSubject<number>(null);
    this.loggedInAuthZ$ = new BehaviorSubject<any>([]);
    this.loggedOutAuthZ$ = new BehaviorSubject<any>([]);
    this.availableKeys$ = new BehaviorSubject<any>([]);

    
    // Get the list of configured sshauthz servers
    this.getSshAuthzServersObservable().subscribe((v) => this.sshAuthzServers$.next(v))

    // every time the backend changes, refresh the list of certs in the agent
    this.backendSelectionService.apiserver.pipe(
      filter((v) => v !== null && v !== undefined),
    )
      .subscribe((value) => { this.backendURI = value.tes ; this.refresh() });

    this.backendSelectionService.apiserver.pipe(
      filter((v) => v !== null && v !== undefined),
      switchMap((v) => this.getSessionToken(v)),
      filter((v) => v !== null && v !== undefined),
    ).subscribe((v) => { this.sessionToken$.next(v); window.localStorage.setItem('strudel2_session', v); this.refresh() } );
    
    // every time the list of certs/keys changes update the list of whats logged in and out
    // sshAuthzServers$ won't really "change" except that it needs to be retrieved first time
    combineLatest([this.availableKeys$, this.sshAuthzServers$.pipe(filter((v) => v !== null ))]).pipe(
      map(([agentContents,authzServers]) => { return this.updateLoggedAuthZ(agentContents,authzServers)}),
    ).subscribe((v) => {this.loggedInAuthZ$.next(v[0]); this.loggedin$.next(v[0].length) ; this.loggedOutAuthZ$.next(v[1]); this.loggedout$.next(v[1].length)})


    this.initKeygenPipelines();
 }

 private compareAvailabeKeys(a,b) {
    if (a.length != b.length) {
      return false;
    }
    for (let i=0; i < a.length; i++) {
      if (a[i].expiry !== b[i].expiry || a[i]["Signing CA"][0] !== b[i]["Signing CA"][0] || a[i]["Key ID"][0] !== b[i]["Key ID"][0]) {

        return false;
      }
    }
    return true;
  }

 public refresh() {
  // 1. get the value of the backend server we are using
  // 2. get the sessionToken (refresh if its still good, get a new one otherwise)
  // 3. Use the sessionToken and api server to get the list of keys
  // 4. Place the list of keys in a behaviour subject
  this.backendSelectionService.apiserver.pipe(
    filter((v) => v !== undefined && v!== null),
    switchMap((apiserver) =>  { 
      return this.getSessionToken(apiserver).pipe(
        map((sessionToken) => [apiserver, sessionToken])
        ) 
      } ),
    switchMap(([apiserver,sessionToken]) => this.updateAgentContents(apiserver, sessionToken))
  ).subscribe((v) => { if (!this.compareAvailabeKeys(v,this.availableKeys$.value)) {this.availableKeys$.next(v)}});
  // if (this.backendURI !== null) {
  //   this.updateAgentContents(this.backendURI).subscribe((v) => this.availableKeys$.next(v));
  // } else {
  //   this.backendSelectionService.apiserver.pipe(
  //     take(1)
  //   ).subscribe((apiserver) => this.updateAgentContents(apiserver.tes).subscribe((v) => this.availableKeys$.next(v)))
  // }
 }

 public storeLocalAuthZ(authz: any) {
  // called from settings
   try {
     localStorage.setItem('localauthservers',JSON.stringify(authz));
   } catch {
   }
 }

 public removeLocalAuthZ() {
   localStorage.removeItem('localauthservers');
 }

public updateFragment(frag) {
  // The second half of login. After we have returned from the authorization server
  // called from keygen
  this.fragment$.next(frag);
 }


public logout() {
  // clear the ssh agent and remove all keys
  // remove the sessionToken from localstorage and memory
  // get a new session token
  let apiserver = this.backendSelectionService.apiserver.value;
  if (apiserver === null) {
    throwError('can not logout, apiserver is not set');
  }
  let sessionToken = this.sessionToken$.value;
  sessionStorage.removeItem('keys');
  window.localStorage.removeItem('strudel2_session');
  this.sessionToken$.next(null);
  this.availableKeys$.next([]);
  return this.killAgent(apiserver, sessionToken).pipe(
    switchMap((v) => this.getSessionToken(apiserver))
  ).
    subscribe((v) => {this.sessionToken$.next(v); this.router.navigate(['/login']) })
}




private getSessionToken(apiserver: APIServer): Observable<any> {
  // look for an existing session token. If present refres the token
  // otherwise get a new token

 let access_token = window.localStorage.getItem('strudel2_session');
 if (access_token) {
   let headers = new HttpHeaders({
     'Authorization': `Bearer ${access_token}`
   })
   let options = { headers: headers, withCredentials: true};
   let pipe = this.http.get<any>(apiserver.tes+'/authping', options).pipe(
     switchMap((v) => this.http.get<any>(apiserver.tes+'/refreshsession', options)),
     catchError((e) => this.http.get<any>(apiserver.tes+'/newsession'))
   )
   return pipe
 } else {
   return this.http.get<any>(apiserver.tes+'/newsession')
 }
}


 private getSshAuthzServersObservable(): Observable<SshAuthzServer[]> {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: false};
  return this.http.get<SshAuthzServer[]>('./assets/config/authservers.json',options)
                   .pipe(catchError(this.handleError('getSshAuthzServers')),
                   map((v) => this.concatLocalAuthZ(<SshAuthzServer[]>v)))
}

 private concatLocalAuthZ(v:SshAuthzServer[]): SshAuthzServer[] {
  var localauths: SshAuthzServer[];
  try {
    localauths = JSON.parse(localStorage.getItem('localauthservers'))
  } catch {
    localauths = []
  }
  if (localauths === null) {
    localauths = [];
  }
  for (let server of localauths){ 
    v.push(server);
  }
  return v;
 }

 private updateLoggedAuthZ(agentContents,authzServers) {
     let loggedin = []
     let loggedout = []
     var found: boolean;
     if (agentContents == null) {
       return
     }
     for (let s of authzServers) {
         found=false;
         for (let cert of agentContents) {
           if ('Signing CA' in cert) {
             for (let ca of cert['Signing CA']) {
               if (ca.indexOf(s.cafingerprint) != -1) {
                 loggedin.push(s)
                 found=true;
                 continue;
               }
             }
           }
           if (found) {
               continue;
           }
         }
         if (!found)  {
           loggedout.push(s)
         }
     }
     return [loggedin, loggedout]
 }
        
 private querySshAgentError(error: any) {
   //this.agentContents.next([]);
   if (error.status == 0) {
     this.notifications.notify("A network error occured. Are you connected to the internet?")
     return;
   }
   if (error.stats == 401) {
    this.sessionToken$.next(null);
    return;
   }
   this.notifications.notify("Error querying ssh agent");
 }

  private updateAgentContents(apiserver: APIServer, sessionToken: string): Observable<any> {
    /* Query ssh agent running on the apiserver 
     * Tap the even stream to update the notifications 
     */

    if (this.ipcService.useIpc) {
      return this.ipcService.sshAgent(null);
    } else {


    if ( sessionToken === null ) {
      throwError('Session Token is null while refreshing agent contents')
    }


   let headers = new HttpHeaders({
    'Authorization': `Bearer ${sessionToken}`
  });
   let options = { headers: headers, withCredentials: true};
   let agentquery$ = this.http.get<any>(apiserver.tes+'/sshagent',options)
   let agentpipe$ = agentquery$.pipe(
     catchError((e) => { this.querySshAgentError(e); return of([])}),
     map((v) => this.addExpiryField(v)),
   )
   return agentpipe$
 }
}

 private addExpiryField(resp): any[] {
  var res: any[]
  res = []
  for (let id of resp) {
    var validstr: String;
    validstr = id.Valid[0];
    id.expiry = Date.parse(validstr.split(" ")[3]+"Z")
    res.push(id);
  }
  return res
 }

 private killAgent(apiserver: APIServer, sessionToken: string) {
   this.notifications.notify("Logging out")
   var anyvar: any;
   if (sessionToken === null) {
    return throwError('cant get the agent contents without a session');
   }
   let headers = new HttpHeaders({
    'Authorization': `Bearer ${sessionToken}`
  });
  let options = { headers: headers, withCredentials: true};
   return this.http.delete<any>(apiserver.tes+'/sshagent',options)
 }



 private handleError<T> (result?: T) {
   return (error: any): Observable<T> => {
     if (error.status == 500) {
       return throwError("The backend server encountered and error. Please try again in a few minutes")
     }
     return throwError(error.message);
     // return of(result as T);
   };
 }

 get idToken(): string {
  return this.oauthService.getIdToken();
}

get oidcAccessToken(): string {
  return this.oauthService.getAccessToken();

}



 private getSshAuthzAccessToken(): Observable<accessTokenClass> {
  let sshauthzServer = <SshAuthzServer>JSON.parse(window.sessionStorage.getItem('oidc_authserver'));
  const headers = new HttpHeaders({})
  const body:  any = {'idToken': this.idToken, 'accessToken': this.oidcAccessToken}

  let options = { headers: headers, withCredentials: false};
  let signingUrl=sshauthzServer.base+'/token'
  return this.http.post<accessTokenClass>(signingUrl, body, options);
}


 private initKeygenPipelines() {


    // in the old model the token was returned in the url fragment. We can observe the URL fragment to get it
    const sshAuthZTokenv1$: Observable<AuthToken> = this.fragment$.pipe(
      filter((v) => v !== null),
      map((v) => this.extractToken(v)),
    );

    // in the new model using angular_oauth2_oidc, the oauthService will have OIDC tokens that we will exchange for an sshauthz access token.
    // we can observe oauthService.events and use swithcMap to exchange the OIDC token for an access token.
    const sshAuthZTokenv2$: Observable<AuthToken> = this.oauthService.events
    .pipe(
      filter((e) => e.type === 'token_received'),
      switchMap((_) => this.getSshAuthzAccessToken()),
      map((v) => new AuthToken(v.access_token, JSON.parse(window.sessionStorage.getItem('oidc_authserver')))),
    );


    const sshAuthZToken$ = merge(sshAuthZTokenv1$,sshAuthZTokenv2$);

    const key$: Observable<SshKeyPair> = from(window.crypto.subtle.generateKey(
      {
        name: "ECDSA",
        namedCurve: "P-256",
      },
      true,
      ["sign","verify"]
    )).pipe(
      switchMap((v) => { return combineLatest([from(window.crypto.subtle.exportKey("jwk",v.privateKey)),from(window.crypto.subtle.exportKey("jwk",v.publicKey))]) }),
      map(([key,pub]) => {
        return {'private': jwktossh.pack({'jwk': key, 'comment': '', 'public': false})+"\n", 'public': jwktossh.pack({'jwk': pub, 'comment': '', 'public': true})+"\n"};
      }),
    )
    const apiserver$: Observable<APIServer> = this.backendSelectionService.apiserver.pipe(
      filter((v) => v !== undefined),filter((v) => v !== null),
    );

    let keycert$: Observable<any> = combineLatest([sshAuthZToken$, key$, apiserver$]).pipe(
      switchMap(([token, key, apiserver ]) => { 
        return this.getCert(token, key, apiserver).pipe(
          map((cert) => [key, cert, token]),
        )
        }),
      tap(([key, cert, token]) => this.logout_sshauthz(token.sshauthzservice))
     )





    // let keycert$ = combineLatest([token$, key$, apiserver$]).pipe(
    //   switchMap( ([token,key,apiserver]) => this.getCert(token,key,apiserver),
    //     ([token,key,apiserver],cert) => [key,cert,token]),
    //   tap(([key,cert,token]) => this.logout_sshauthz(token.sshauthzservice)),
    // );

    let agent$ = combineLatest([keycert$.pipe(filter((v) => v !== null)),apiserver$,this.sessionToken$.pipe(filter((v) => v !== null))]).pipe(
      switchMap(([keycert,apiserver,sessionToken]) => this.addCert(keycert,apiserver)),
      tap((v) => this.refresh()),
      //switchMap((_) => this.updateAgentContents()),
      //switchMap((_) => this.loggedInAuthZ),
      switchMap((_) => of([null])),
    );
    agent$.subscribe( (res) => this.loginCompleteNavigate(),
                      (err) => { console.error(err) ; 
                                 if (err.sshauthzservice !== undefined ) {
                                    this.logout_sshauthz(err.sshauthzservice)
                                    this.router.navigate(['/noaccount',err.sshauthzservice.name])
                                 } else {
                                    this.router.navigate(['/login'])}
                                 } )
  }


  private loginCompleteNavigate() {
    let path = sessionStorage.getItem('path');
    if (path ) {
      this.router.navigate([path]);
    } else {
      this.router.navigate(['/']);
    }
  }

 private extractToken(frag: string) {
   if (frag === undefined || frag == null) {
       return;
   }
   let tokenmatch = null;
   let statematch = null;
   if (!(frag === undefined) && !(frag == null)) {
     tokenmatch = frag.match(/access_token\=([^&]+)(&|$)/);
     statematch = frag.match(/state\=([^&]+)(&|$)/);
   }
   if (tokenmatch == null || statematch == null) {
     throw new Error('no token present');
   }

   let accesstoken = tokenmatch[1];
   let state = statematch[1];
   let tuple = JSON.parse(sessionStorage.getItem('authservice'));
   if (tuple[1] != state) {
     throw new Error('callback state parameter does not match'+frag+tuple);
   }

   return new AuthToken(tokenmatch[1],tuple[0]);

 }

  private logout_sshauthz(sshauthzserver) {
   if (sshauthzserver !== undefined && sshauthzserver.logout !== null) {
     window.open(sshauthzserver.logout);
   }
  }

  private getCert(token: AuthToken, key: SshKeyPair, apiserver: APIServer): Observable<any> {

    let headers = new HttpHeaders({'Authorization':'Bearer '+token.token});
    let options = { headers: headers, withCredentials: false};
    var data: any;


    if (token.sshauthzservice.caname === undefined || token.sshauthzservice.caname === null) { // Using the old SSHAuthZ server
      var now = new Date()
      var end = new Date(now.getTime() + 28*24*60*60*1000); //request a certificate valid for 28 days
                                                            //its expected that the user will terminate the session by closing their browser/sleeping their laptop before this
      //var end = new Date(now.getTime() + 30*1000); // Uncomment if you want to test certificates expiring
      data = {'public_key': key.public, 'end': end.toISOString()};

      return this.http.post<any>(token.sshauthzservice.sign,data, options).pipe(
        map((v) => v.certificate),
        catchError((e) => { console.error('error in getcert'); console.error(e); return throwError(token) })
      )

    } else {
      data = new signingRequestClass()
      data.ca = token.sshauthzservice.caname, 
      data.pubkey = key.public
      data.params = new certParamsClass();
      data.params.period = 28*24*60*60;
      data.params.cert_type = 'user';

      return this.http.get<any>(token.sshauthzservice.base+'/tokeninfo',options).pipe(
        switchMap((v) => {
            data.params.principals = v.authz[`${token.sshauthzservice.caname}`].principals;
            data.params.period = v.authz[`${token.sshauthzservice.caname}`].period;
            return this.http.post<any>(token.sshauthzservice.base+'/sign',data,options)
          }
        )
      )


    }
    return this.http.post<any>(token.sshauthzservice.sign,data, options).pipe(
      map((v) => v.certificate),
      catchError((e) => { console.error('error in getcert'); console.error(e); return throwError(token) })
    )
  }

  private addCert(kclist: any, apiserver: APIServer): Observable<any> {
    let keyCert = new KeyCert()
    keyCert.key = kclist[0].private
    keyCert.cert = kclist[1]
    this.storeKey(keyCert); 
     let data = {'key': keyCert.key, 'cert': keyCert.cert};
      if (this.ipcService.useIpc) {
        return this.ipcService.addCert(data);
      } else {
  
        if (this.sessionToken$.value === null) {
          throwError('Session token is null how is this possible')
        }
  
        let headers = new HttpHeaders({
          'Authorization': `Bearer ${this.sessionToken$.value}`
        });
        let options = { headers: headers, withCredentials: true};
        let data = {'key': keyCert.key, 'cert': keyCert.cert};
        return this.http.post<any>(apiserver.tes+'/sshagent',data,options);

      }
  }


  private storeKey(keyCert: KeyCert) {
    var keys: KeyCert[] = [];
    try{
      keys = JSON.parse(sessionStorage.getItem('keys'));
    } catch {
      keys = [];
    }
    if (keys === null) {
      keys = [];
    }
    keys.push(keyCert);
    if (keys.length > 10) {
      console.error('keys is growing too fast')
      keys = keys.slice(-9)
    }
    sessionStorage.setItem('keys',JSON.stringify(keys))
  }



  
}
