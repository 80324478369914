import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';


@Component({
  selector: 'app-externdialog',
  templateUrl: './externdialog.component.html',
  styleUrls: ['./externdialog.component.css']
})
export class ExterndialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExterndialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }


  close(rv) {
    this.dialogRef.close(rv);
  }

}
