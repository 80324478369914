
<div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between; box-sizing: border-box;">
    <!--<mat-accordion style="width: 100%" [displayMode]="flat" [togglePosition]="'after'">-->
    <div *ngIf="appSubject | async as app">
    <mat-accordion *ngIf="(settingsService.appdesc == true) && (app.desc !== undefined && app.desc !== null)" style="width: 100%" [displayMode]="'flat'">
        <mat-expansion-panel style="width: 100%" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title> 
                    INSTRUCTIONS/DESCRIPTION
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="mat-body-2" style="flex: 1" [innerHTML]="app.desc | safeHtml" style="width: 100%"></div>
        </mat-expansion-panel>
    </mat-accordion>
    </div>
    <mat-accordion style="width: 100%" [displayMode]="'flat'">
        <mat-expansion-panel style="width: 100%" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title> 
                    LAUNCH {{ getAppName() }}
                </mat-panel-title>
            </mat-expansion-panel-header>
        
            <!-- <div fxFlex style="background: blue">app config</div> -->
            <!--<iframe *ngIf="appconfigurl != null"  fxGrow=10 fxFlex [src]="appconfigsafeurl" style="border: none"></iframe>
            <iframe *ngIf="appconfigurl != null" [src]="batchcmdsafeurl" style="height: 1px; min-height: 0px; border: none" #batchbuilderiframe></iframe>-->


            <div *ngIf="appSubject | async as app" style="width: 100%">
                <div *ngIf="app.submitcmd">
                    <app-launchform [app]="app" [identity] = "this.identity" (data)="this.submitparams"></app-launchform>
                </div> 
                <div *ngIf="!(app.submitcmd)" style="display: flex; flex-direction: row;  justify-content: space-around; align-items: center;" >
            
                    <iframe *ngIf="app.startscript !== null" [src]="batchcmdsafeurl" class="iframe-box" [style.height]="height+'px'" [style.width]="width+'px'" #batchbuilderiframe></iframe>
                    <!--<div *ngIf="appconfigsafeurl !== null && configtoggle">
                        <iframe [src]="appconfigsafeurl" style="border: none; border-style: none; border-width: 0px; width: 100%" [style.height]="appconfigheight+'px'" #batchbuilderiframe></iframe>
                    </div>-->

                    <mat-list>
                        <mat-list-item *ngIf="appconfigsafeurl !== null"><button mat-flat-button (click)="configdialog()" color="primary">Configure</button></mat-list-item>
                        <mat-list-item *ngIf="app.startscript !== null"><button mat-flat-button (click)="launch()" color="primary" #launchbtn [disabled]="!readyToLaunch">Launch</button></mat-list-item>
                        <mat-list-item *ngFor="let action of app.appactions"><button mat-flat-button (click)="runaction(action)" color="primary"  >{{ action.name }}</button></mat-list-item>
                    </mat-list>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div>&nbsp;<div>
