


import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EnvLoaderService {

  //public authConfig?: AuthConfig;
  public logserver?: string;
  public contact?: string[];
  public name?: string = "STRUDEL";
  public about_slides?: string;
  public default_theme?: string;
  public loginterval?: number;
  public docs_url?: string;

  constructor(private httpClient: HttpClient) { }

  // export const environment = {
  //   production: true,
  //   contact: ["<STRUDEL Developers> chris.hines@monash.edu, jafar.lie@monash.edu, lance.wilson@monash.edu"],
  //   logserver: "https://beta-api.cloud.cvl.org.au/logging",
  //   loginterval: 1000000,
  //   name: "STRUDEL",
  //   about_slides: null,
  //   default_theme: "strudel-light-theme"
  // };



  initializeAppFactory() {
    return this.httpClient.get<any>('./assets/config/env.json')
    .pipe(tap((response) => {
      //this.authConfig = <AuthConfig>response.oidcConfig;
      this.logserver = <string>response.logserver;
      this.contact = <string[]>response.contact;
      this.name = <string>response.name;
      this.about_slides = <string>response.about_slides;
      this.default_theme = <string>response.default_theme;
      this.loginterval = <number>response.loginterval;
      this.docs_url = <string>response.docs_url;
    }))
  }

}
export function PreloadInitialize(envLoaderService: EnvLoaderService) {
  return () => envLoaderService.initializeAppFactory();
}