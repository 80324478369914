import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';
import { Observable, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Job } from './job';
import { AppAction, Strudelapp, StrudelappInstance } from './strudelapp';
//import {Window} from './global';


declare global {
  interface Window {
    "electronApi": {
      addCert: (data:  any,   callback: any) => void;
      register_oauth2_redirect: (fn: any) => void;
      launch: (job: Job, appinst: StrudelappInstance, action: AppAction, callback: any) => void;
      runRemoteCommand: (data: any, callback: any) => void;
      createTunnel: (data: any, callback: any) => void;
      sshAgent: (data: any, callback: any) => void;
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class IpcService {
  private _ipc: IpcRenderer | undefined;
  public useIpc: boolean = false;

  constructor() {
    if (window.electronApi) {
      this.useIpc = true;
    } else {
      this.useIpc = false;
    }
  }
  public addCert(data): Observable<any> {
      return new Observable<any>( (observer: Observer<any>) => { window.electronApi.addCert(data, () => { observer.next(true) } ) } )
  }

  public register_oauth2_redirect(fn: (arg0: string) => void) {
    if (!(window.electronApi == undefined) && this.useIpc) {
      window.electronApi.register_oauth2_redirect(fn);
    }
  }


  public launch(job: Job, appinst: StrudelappInstance, action: AppAction): Observable<any> {
    return new Observable<any>( (observer: Observer<any>) => {
      window.electronApi.launch(job, appinst, action, (arg) => { 
        if (arg[0] !== null) {
          observer.error(arg[2])
        } else {
          observer.next(arg[1]);
        }
      } )
    } )
  } 

  public runRemoteCommand(data: any): Observable<any> {
    return new Observable<any>( (observer: Observer<any>) => {
      window.electronApi.runRemoteCommand(data, (arg) => { 

        console.log('run RemoteCommand  came back with', arg)
        if (arg[0] !== null) {
          var error;
          try {
            error = JSON.parse(arg[2]);
          } catch {
            error = {'error': arg[2]};
          }
          observer.error(error);
          observer.complete();
        } else {
          var msg;
          try {
            msg = JSON.parse(arg[1]);
          } catch {
            msg = {'msg': arg[1]}
          }
          observer.next(msg);
          observer.complete();
        }
      } )
    } )
  }
  


  public createTunnel(data: any): Observable<any> {
    return new Observable<any>( (observer: Observer<any>) => {
      window.electronApi.createTunnel(data, (arg) => { 
        if (arg[0] !== null) {
          observer.error(arg[2])
        } else {
          observer.next(arg[1]);
        }
      } )
    } )
  }
  
  public sshAgent(data: any): Observable<any> {
    return new Observable<any>( (observer: Observer<any>) => {
      window.electronApi.sshAgent(data, (arg) => { 
        if (arg[0] !== null) {
          observer.error(arg[2])
        } else {
          observer.next(arg[1]);
        }
      } )
    } )
  }
}