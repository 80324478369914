<div class="other-component" style="display: flex; flex-direction: row; justify-content: space-around">    
<div style="display: flex; flex-direction: column" class="content">
    <div class="heading"><h1>ABOUT US</h1></div>
    <mat-divider></mat-divider>
    <div>&nbsp;</div>

        <iframe
        *ngIf="slides != null"
        [src]="slides"
        [width]="800"
        [height]="600"></iframe>

    <p>Strudel is a cross-platform application that is used to access interactive desktop sessions on the high performance computing systems and cloud environments used by MASSIVE (M3) and the CVL.</p>
    <div>
        <button mat-flat-button color=primary class="big-button" onClick="window.open('https://www.cvl.org.au/cvl-desktop/getting-started-with-the-cvl')">READ MORE</button>
    </div>

</div>
</div>
