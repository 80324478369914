<div class="content" style="display: flex; flex-direction: column; justify-content: flex-start; align-items: center">
    <div style="display: flex ; flex-direction: row; align-items: center; justify-content: center; width: 30%">
        <div class="content" style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; color: var(--panel-header-color); margin: 30px">
            <div class="heading"><h1>LOGIN</h1></div>
            <mat-divider style="width: 100%"></mat-divider>
            <div style="height: 50px"></div>
                <mat-form-field style="width: 100%; background-color: transparent;">
                    <mat-label >Choose a service</mat-label>
                    <mat-select [(value)]="selected">
                        <mat-option *ngFor="let sshauthzserver of loggedOutAuthZ" [value]="sshauthzserver">
                            {{ sshauthzserver.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            <div style="height: 50px"></div>
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%">
                <button  mat-flat-button (click)="login()" [disabled]="selected === undefined" color=primary style="width: 25%; text-align: center">Login</button>
            </div>
            <div style="height: 50px"></div>
            <div *ngIf="selected !== undefined" [innerHTML]="selected.desc | safeHtml"></div>
        </div>
    </div>
</div>
