import { Component, OnInit, ViewChild, ElementRef, Inject, Input } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { Renderer2, SimpleChange } from '@angular/core';
//import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { TesService } from '../tes.service';
import { Observable, merge, pipe, timer, Subscription} from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { take, skip } from 'rxjs/operators';
import { Identity } from '../identity';
import { Strudelapp, AppAction } from '../strudelapp';
import { BatchInterface} from '../batchinterface';
import { fromEvent } from 'rxjs';
import { Job } from '../job';
import { BrowserWindowService } from '../browser-window.service';
import {SettingsService} from '../settings.service';
import { EnvLoaderService } from '../env-loader.service';


@Component({
  selector: 'app-launch-dialog',
  templateUrl: './launch-dialog.component.html',
  styleUrls: ['./launch-dialog.component.css']
})
export class LaunchDialogComponent implements OnInit {
  @Input() identity: Identity;
  @Input() appSubject: BehaviorSubject<Strudelapp>;
  //@Input() app: Strudelapp;
  app: Strudelapp;
  batchcmdurl: string;
  appconfigurl: string;
  batchcmdsafeurl: any;
  appconfigsafeurl: any;
  submitparams: any;
  rmListen: any;
  @ViewChild('launchbtn', { read: ElementRef }) launchbtn: ElementRef;
  @ViewChild('batchbuilderiframe', { read: ElementRef }) batchbuilderiframe: ElementRef;
  setFocus: Boolean;
  subscriptions: Subscription[];
  launchNext: Boolean;
  width: number;
  appconfigwidth: number;
  height: number;
  public appData: BehaviorSubject<any>;
  public submitcmd: BehaviorSubject<string>;
  configtoggle: boolean;
  configwindow: any;
  public readyToLaunch: boolean;
  private openWindowSub: Subscription;

  //constructor( public dialogRef: MatDialogRef<LaunchDialogComponent>,
  constructor( 
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2,
    private tesService: TesService,
    private browserWindowService: BrowserWindowService,
    public settingsService: SettingsService,
    private envLoaderService: EnvLoaderService
  ) {
    this.submitcmd = new BehaviorSubject<string>(null);
    this.appData = new BehaviorSubject<any>(null);
    this.subscriptions = [];
    this.launchNext = false;
    this.height=0;
    this.submitcmd.subscribe(() => this.canILaunch());
    this.appData.subscribe(() => this.canILaunch());
    this.browserWindowService.cancelJob$.subscribe((v) => this.tesService.cancel(v))

  }

  ngOnInit() {
    this.rmListen = this.renderer.listen('window','message',(event) => this.receiveMessage(event));
    this.setFocus = false;
    this.updateURLs();
    this.clearInfo();
    this.openWindowSub = this.tesService.openWindow$.subscribe((v) => {  this.browserWindowService.openAppWindow(v.job,v.url,v.usebasicauth,v.action,v.appinst)})
    this.subscriptions.push(this.appSubject.subscribe(() => { this.app = this.appSubject.value; this.updateURLs() ; this.clearInfo()}));
  }

  configdialog(){
    this.configwindow = window.open(this.app.url);
    this.configwindow.onunload = function () { };
    this.subscriptions.push(fromEvent(this.configwindow,'message').subscribe((event) => this.receiveAppconfig(event)));
  }

  updateURLs() {
    if (this.app == null) {
      return;
    }
    let theme = "";
    theme=localStorage.getItem('strudel-theme');
    if (theme == null) {
      theme = this.envLoaderService.default_theme;
    }
    if (this.identity !== null && this.app !== null) {
      if (this.app.batchcmdurl !== null && this.app.batchcmdurl !== undefined) {
        this.batchcmdurl = this.app.batchcmdurl+"?theme="+theme;
      } else {
        this.batchcmdurl = this.identity.site.url+"/"+encodeURIComponent(this.app.name)+"?theme="+theme;
      }
    }
    this.batchcmdsafeurl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.batchcmdurl);

    if (this.app.url !== null) {
      this.appconfigurl = this.app.url+"?theme="+theme;
    } else {
      this.appconfigurl = null;
    }

    if (!(this.appconfigurl == null)) {
      this.appconfigsafeurl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.appconfigurl);
    } else {
      this.appconfigsafeurl = null;
    }
  }

  ngOnDestroy() {
    this.rmListen();
    for (let s of this.subscriptions) {
      s.unsubscribe();
    }
    this.openWindowSub.unsubscribe();
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {

    this.updateURLs();
  }

  setReady(ready) {
      this.readyToLaunch = ready;
      if (this.launchbtn === undefined) {
          return
      }
      if (ready && !(this.setFocus)) {
        this.launchbtn.nativeElement.focus();
        this.setFocus = true;
      }
  }


  receiveAppconfig(event) {
      if (event.data['appData'] !== undefined)  {
        this.appData.next(event.data['appData']);
      }
  }
  receiveMessage(event) {
      if (event.data['batchcmd'] !== undefined)  {
        this.submitcmd.next(event.data['batchcmd']);
      }
      if (event.data['resize'] !== undefined) {
        this.height = event.data['resize']['height'];
        this.width = event.data['resize']['width'];
      }
  }

  runaction(action: AppAction) {
    if (action.paramscmd.indexOf('{submitcmd}') != -1) {
      this.batchbuilderiframe.nativeElement.contentWindow.postMessage('launch selected',"*");
      this.submitcmd.pipe(skip(1),take(1)).subscribe( (submitcmd) => {  this.reallyRunAction(submitcmd,action)})
    } else {
      this.reallyRunAction('',action);
    }
  }

  reallyRunAction(submitcmd: string, action: AppAction) {
    var job: Job;
    var newaction: AppAction;
    job =  new Job();
    job.identity = this.identity;
    job.batch_host = 'localhost';
    job.jobid='1';
    newaction = new AppAction();
    newaction.paramscmd = action.paramscmd.replace(/\{submitcmd\}/,submitcmd);
    newaction.notunnel = action.notunnel;
    newaction.client = action.client;
    newaction.postData = action.postData;
    this.tesService.connect(job,newaction);
  }

  launch() {
    // tell the batchbuilder iframe that lanch was selected
    // This is the cue to save the values as defaults for next launch
    this.batchbuilderiframe.nativeElement.contentWindow.postMessage('launch selected',"*");
    this.submitcmd.pipe(skip(1),take(1)).subscribe( (submitcmd) => this.reallyLaunch(submitcmd))
    this.launchNext=true;
  }

  reallyLaunch(submitcmd) {
    let bi = new BatchInterface();
    bi.submitcmd = submitcmd;
    if (this.appData.value != null){ 
      let appparams = this.appData.value;
      this.tesService.submit(this.app,this.identity,bi,appparams);
    } else {
        this.tesService.submit(this.app,this.identity,bi);
    }
  }

  clearInfo() {
      this.appData.next(null);
      this.submitcmd.next(null);
  }

  canILaunch() {
    if (this.app == null) {
      this.setReady(false);
      return
    }
    if (this.submitcmd.value != null) {
        if (this.app.url === null || this.appData.value != null ) {
            this.setReady(true);
            return;
        }
    }
    this.setReady(false);
  }

  public getAppName(): string { 
    // default name if this function fails to get the app name
    let appName = "job";
    if (this.appSubject.value.name !== undefined) {
      appName = this.appSubject.value.name;
    }
    
    return appName.toUpperCase();
  }
}
