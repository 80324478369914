import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private msg: BehaviorSubject<[string,any]>;
  private afterNotifyFn: BehaviorSubject<any>;

  constructor() { 
    this.msg = new BehaviorSubject<[string,any]>([null,null]);
  }

  getMsg() {
    return this.msg;
  }


  notify(msg: any, afterFn : any = null) {
    if (msg === null || msg === undefined) {
      this.msg.next(["",null]);
      return
    }
    if (msg.hasOwnProperty('error')) {
      this.notify(msg.error, afterFn);
      return;
    }
    if (msg.hasOwnProperty('message')) {
      this.notify(msg.message, afterFn);
      return;
    }
    if (msg.hasOwnProperty('msg')) {
      this.notify(msg.msg, afterFn);
      return;
    }
    this.msg.next([msg, afterFn]);
    console.log(msg);
  }


}
