<!-- 
<div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; width: 40ch">
<mat-form-field>
<input matInput type="number" step="1" min="0" max="99" data-hour aria-label="days" placeholder="-" style="flex: 0; width: 2ch">
<slot name="days" part="divider"><span style="width: 1ch">-</span></slot>
</mat-form-field>

<mat-form-field style="width: 3em">
<input matInput type="number" step="1" min="0" max="23" data-hour aria-label="hour" placeholder="--" style="flex: 0; width: 2ch">
<slot name="hour" part="divider" style="width: 1ch"><span style="width: 1ch">:</span></slot>
</mat-form-field>

<mat-form-field>
<input matInput type="number" step="1" min="0" max="59" data-minute aria-label="minute" placeholder="--" style="flex: 0; width: 2ch">
<slot name="minute" part="divider"><span style="width: 1ch">:</span></slot>
</mat-form-field>

<mat-form-field>
<input matInput type="number" step="1" min="0" max="59" data-second aria-label="second" placeholder="--" style="flex: 0; width: 2ch">
<slot name="second" part="divider"><span style="width: 1ch">:</span></slot>
</mat-form-field>

</div> -->


<mat-form-field style="flex: 1">
   <mat-label>{{label}}</mat-label>
   <input
    [formControl]="field"
    [type]="'text'"
    (keydown)="onKeyPress($event)"
    autocomplete="off"
    matInput>
    @if (errormsg !== '') {
      <mat-error>{{errormsg}}</mat-error>
    }

</mat-form-field>




 <!--

    <input matInput #duration type="string" placeholder="--:-- --" aria-label="duration">-->
