export class certParamsClass {
    period: number = 0;
    principals: string[] = [];
    cert_type: string = "";
    any_principal: boolean = false;
}

export class signingRequestClass {
    ca: string = "";
    pubkey: string | null  = null;
    params: certParamsClass | null = null;
}

export class accessTokenClass {
    access_token: string = "";
    token_type: string = "";
}