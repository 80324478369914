import { Injectable } from '@angular/core';
import { Computesite } from './computesite';
import { Strudelapp } from './strudelapp';
import {BehaviorSubject, of, combineLatest, ReplaySubject, forkJoin, throwError} from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap, filter, switchMap, startWith} from 'rxjs/operators';
import { Identity } from './identity';
import { TesService } from './tes.service';
import { AuthorisationService} from './authorisation.service';
import { NotificationsService } from './notifications.service';



@Injectable({
  providedIn: 'root',
})
export class ComputesitesService {
  public computesites: BehaviorSubject<Computesite[]>; // this is used in the contactus form to determine the list of contacts
  private identities$: Observable<[Identity[],Identity[],Identity[]]>;
  public appidentities$: BehaviorSubject<Identity[]>;

  constructor(private tesService: TesService, private http: HttpClient, private authorisationService: AuthorisationService, private notifications: NotificationsService) {
    this.computesites = new BehaviorSubject<Computesite[]>([]);
    this.getComputeSites();

    this.identities$ = combineLatest([this.authorisationService.availableKeys$,this.computesites.pipe(filter((v) => v.length !== 0))])
                          .pipe( 
                            switchMap((v) => this.combine_cert_site(v[0],v[1]))
                          ) 

    this.appidentities$ = new BehaviorSubject<Identity[]>([]);
    // this.appidentities$ = this.identities$.pipe(
    //   map((v) => v[2]),
    //   switchMap((ids) => this.getStrudelAppsIdsObservable(ids)),
    //   );
    this.setAppIdentities();

  }

  setAppIdentities() {

    combineLatest([this.authorisationService.availableKeys$,this.computesites.pipe(filter((v) => v.length !== 0))])
            .pipe( 
              switchMap((v) => this.combine_cert_site(v[0],v[1])),
              map((v) => v[2]),
              switchMap((ids) => this.getStrudelAppsIdsObservable(ids)),
            ).subscribe((v) => { this.appidentities$.next(v)})
  }

  getStrudelAppsIdentity(identity: Identity): Observable<Strudelapp[]> {
    // retrive the list of apps available to this user at this compute site
    let uri$ = this.getStrudelAppsUri(identity.site.appCatalogUri);
    let cmd$ = this.getStrudelAppsCmd(identity);
    return combineLatest([uri$, cmd$]).pipe(
      map(([uri,cmd]) => (uri.concat(cmd).concat(identity.site.appCatalog)))
    )
  }

  getStrudelAppsIdsObservable(ids: Identity[]): Observable<Identity[]> {
    // for each available compute site this user can log into, retrieve all available apps
    // combine with forkJoin (note this is super slow is one site is down as we need it to timeout)
    if (ids != undefined && ids != null) {
      var req: Observable<Identity>[] = []
      if (ids.length == 0) {
        return of([]);
      }
      for (let id of ids) {
        req.push(this.getStrudelAppsIdentity(id).pipe(
          tap((v) => id.appCatalogStatic = v), 
          map((apps) => id)
        ))
      }
      //return forkJoin(req);
      return combineLatest(req);
    }
    return throwError('Looked for the appcatalog on an undefined list of ids');
  }


  private getStrudelAppsUri(uri): Observable<Strudelapp[]> {
      // retrieve the public list of applications (if there is one)
      if (uri !== undefined && uri !== null) {
        let headers = new HttpHeaders();
        let options = { headers: headers, withCredentials: false};
        return this.http.get<Strudelapp[]>(uri,options)
          .pipe(catchError(this.handleError('getStrudelApps', <Strudelapp[]>[])),
          startWith([]))
      }
      return of([] as Strudelapp[] )
  }

  private getStrudelAppsCmd(identity: Identity): Observable<Strudelapp[]> {
    // retrieve the users list of applications (by running a command at the compute site)
    if (identity.site.appCatalogCmd != null) {
      return (this.tesService.runCommand(identity,identity.site.appCatalogCmd) as Observable<Strudelapp[]>)
                    .pipe(
                      catchError((err) => this.handleAppsCmdError(identity, err)),
                      catchError(this.handleError('getStrudelApps', <Strudelapp[]>[])),
                      startWith([]))
    }
    return of([] as Strudelapp[])
  }


  handleAppsCmdError(identitiy: Identity, err: any): Observable<Strudelapp[]> {
    this.notifications.notify('unable to retrieve a list of applications for '+identitiy.site.name)
    return of ([] as Strudelapp[])
  }

  updateStrudelApps(id: Identity, apps) {
    var sapps: Strudelapp[];
    var localapps: Strudelapp[];
    var currentApps: Strudelapp[];

    sapps = <Strudelapp[]>apps;
    currentApps = id.appCatalog.value;
    id.appCatalog.next(currentApps.concat(sapps));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }


  getComputeSites() {
    let headers = new HttpHeaders();
    let options = { headers: headers, withCredentials: false};
    this.http.get<Strudelapp[]>('./assets/config/computesites.json',options)
                     .pipe(catchError(this.handleError('getComputeSites')))
                     .subscribe(resp => this.updateComputeSites(resp));
  }

  storeLocalComputeSites(computesites: any) {
      try {
        localStorage.setItem('localcomputesites',JSON.stringify(computesites));
      } catch {
      }
      this.getComputeSites();
  }

  removeLocalComputeSites() {
      localStorage.removeItem('localcomputesites');
      this.getComputeSites();
  }

  updateComputeSites(resp) {
    var localcomputesites: Computesite[] = [];
    var computesites: Computesite[] = []
    try {
        localcomputesites = JSON.parse(localStorage.getItem('localcomputesites'))
    } catch {
        localcomputesites = []
    }
    if (localcomputesites !== null ) {
      for (let cs of localcomputesites) {
        computesites.push(cs);
      }
    }
    for (let cs of resp) {
      let computesite = <Computesite>cs;
      computesites.push(computesite);
    }
    this.computesites.next(computesites);
  }


  private siteMatch(cert: any, cs: Computesite): string[] {
    var fp: string = (<Computesite>cs).cafingerprint;
    if ('Signing CA' in cert) {
      for (let ca of cert['Signing CA']) {
        if (ca.indexOf(fp) != -1) {
          return cert['Principals']
        }
      }
    }
    return [];
  }

/*   private updateIdentities(agentContents,computesites) {
    let certs = agentContents;
    var cs: Computesite;
    var identities: Identity[] = [];
    var appidentities: Identity[] = [];
    var ftidentities: Identity[] = [];
    // If the agent contents is set to null we are probably still updating it

    if (agentContents == null) {
      return
    }
    for (cs of computesites) {
      for (let i in certs) {
        let principals = this.siteMatch(certs[i],cs);
        for (let principal of principals) {
          if (principal != null) {
              let id = new Identity(principal,cs,certs[i].expiry);
              identities.push(id);
              if (cs.appCatalogUri !== null || cs.appCatalogCmd !== null || cs.appCatalog !== null) {
                appidentities.push(id);
              }
              if (cs.dtn != null ) {
                ftidentities.push(id);
              }
            }
          }
      }
      }
      this.identities.next(identities);
      this.ftidentities.next(ftidentities);
      this.appidentities.next(appidentities);
    } */
    private combine_cert_site(agentContents,computesites): Observable<[Identity[],Identity[],Identity[]]> {
      let certs = agentContents;
      var cs: Computesite;
      var identities: Identity[] = [];
      var appidentities: Identity[] = [];
      var ftidentities: Identity[] = [];
      // If the agent contents is set to null we are probably still updating it
  
      if (agentContents == null) {
        return
      }
      for (cs of computesites) {
        for (let i in certs) {
          let principals = this.siteMatch(certs[i],cs);
          for (let principal of principals) {
            if (principal != null) {
                let id = new Identity(principal,cs,certs[i].expiry);
                identities.push(id);
                if (cs.appCatalogUri !== null || cs.appCatalogCmd !== null || cs.appCatalog !== null) {
                  appidentities.push(id);
                }
                if (cs.dtn != null ) {
                  ftidentities.push(id);
                }
              }
            }
        }
        }
        return of([identities,ftidentities,appidentities]);
      }

}
