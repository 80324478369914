import { Injectable} from '@angular/core';
import {  HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject,  of,  combineLatest } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError, map, tap, filter } from 'rxjs/operators';
import { Job } from './job';
import { AppAction, Strudelapp } from './strudelapp';
import { Health } from './computesite';
import { Identity } from './identity';
import { BatchInterface } from './batchinterface';
import { repeat, takeUntil, take, switchMap } from 'rxjs/operators';
import { LocationStrategy, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorisationService } from './authorisation.service';
import { BackendSelectionService } from './backend-selection.service';
import {BrowserWindowService} from './browser-window.service';
import { NotificationsService } from './notifications.service';
import { APIServer } from './apiserver';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { IpcService } from './ipc.service';
import { v4 as uuid } from 'uuid';


/** The TES service contains ways to start Tunnels, and Execute programs
Its also responsible for querying a compute site for running jobs */



@Injectable({
  providedIn: 'root',
})
export class TesService {
public Base: string;
public jobs: any[];

private cancelRequests$: Subject<boolean>;
public openWindow$: Subject<any>;


  constructor(private http: HttpClient,
              private authorisationService: AuthorisationService,
              private backendSelectionService: BackendSelectionService,

              private notifications: NotificationsService,
              private ipcService: IpcService,
  ) {
    this.cancelRequests$ = new Subject<boolean>();
    this.openWindow$ = new Subject<any>();
    this.backendSelectionService.apiserver.subscribe( (value) => { if (value != null) { this.Base = value.tes }});
 }

 public cancelHealthRequests() {
    this.cancelRequests$.next(true);
 }

getUserHealthObservable(identity: Identity)  {
  let headers = new HttpHeaders({
    'Authorization': `Bearer ${this.authorisationService.sessionToken$.value}`
  });
  //let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  let params = new URLSearchParams();
  if (identity === undefined || identity === null) {
    return
  }
  if (identity.site.userhealth === undefined) {
    identity.accountalerts.next([]);
    return
  }
  params.set('statcmd',JSON.stringify(identity.site.userhealth));
  params.set('host',JSON.stringify(identity.site.host));
  params.set('username',JSON.stringify(identity.username));
  //this.cancelRequests$.next(false);
  return this.runCommand(identity,identity.site.userhealth)
    .pipe(
      takeUntil(this.cancelRequests$),
      catchError((err) => this.networkError(err)),
      catchError((err) => this.authError(err)),
      catchError((err) => this.accountAlertError(err))
    )
}


public accountAlertError(error): Observable<any> {
  if (error.hasOwnProperty('stats' && (error.status == 500 || error.status == 503))) {
    return of([{'type': 'message', 'msg':'Unable to retrieve account info'}])
  }
}
public networkError(error): Observable<any> {
  if (error.hasOwnProperty('status') && error.status == 0) {
    this.notifications.notify("A network error occurred. Are you connected to the internet?")
    return of([])
  } 
  return throwError(error);
}


public authError(error): Observable<any> {
  if (error.hasOwnProperty('status') && error.status == 401) {
    this.cancelHealthRequests();
    return of([])
  } 
  return throwError(error);
}


public getHealthAlertsObservable(identity: Identity) {
  return combineLatest([this.getUserHealthObservable(identity)])
}

public getCachetIncidentsObservable(identity: Identity) {
  let headers = new HttpHeaders({
    'Authorization': `Bearer ${this.authorisationService.sessionToken$.value}`
  });
  //let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: false};
  let params = new URLSearchParams();
  if (identity.site.cacheturis === undefined ||  identity.site.cacheturis.length == 0) {
    identity.systemalerts.next([]);
    return
  }
  for (let uri of identity.site.cacheturis) {
    this.http.get(uri,options)
      .pipe(takeUntil(this.cancelRequests$))
      .subscribe(resp => this.addCachetIncidents(identity,resp), error => this.getCachetIncidentsError(error,identity));
  }
}

private addCachetIncidents(identity,resp) {
  let ci = [];
  for (let i of resp.data) {
    if (i.status == 3 || i.status == 4) {
      continue;
    }
    let h = new Health();
    h.stat = 'error';
    h.msg = i.message;
    ci.push(h);
  }
  identity.systemalerts.next(ci);
}

public addUserHealth(identity,resp) {
  let ci = []
  if (resp !== undefined && resp !== null) {
    for (let i of resp) {
      let h = new Health();
      h.stat = i.stat;
      h.msg = i.message;
      if (i.title != undefined) {
        h.title = i.title;
      }
      if (i.type != undefined) {
        h.type = i.type;
        h.data = i.data;
      }
      ci.push(h);
    }
  }
  identity.accountalerts.next(ci);
}

 private getCachetIncidentsError(error: any, identity: Identity) {
   if (error.status == 0) {
     return
   }
   console.error(error);
   if (error.status == 401) {
     console.error('getCacheIncidentsError');
    this.notifications.notify("Your login has expired. Please log in again",   () => { this.authorisationService.refresh(); }  );
     return
   }
   if (error.status == 400) {
       this.notifications.notify(error);
       return
   }
 }


 submissionError(identity: Identity, error: any) {
  console.error('submissionError',identity.expiry,Date.now());
  if (identity.expiry !== null && identity.expiry < Date.now()) {
    console.error('submissionError',identity.expiry,Date.now());
    console.log(error);
    //this.notifications.notify("Your login has expired. Please log in again",   () => { this.authorisationService.updateAgentContents().subscribe( () => {return} ) }  );
    this.notifications.notify("Your login has expired. Please log in again", () => this.authorisationService.refresh())
    return;
  }

  return this.handleGenericError(identity,error,null);
  //  if (error.status != 0) {
  //      try {
  //        this.notifications.notify(error);
  //        console.error(error);
  //      } catch {
  //        this.notifications.notify('Job Submission failed');
  //        console.error(error);
  //      }
  //  }
 }

 cancelError(error: any) {
   if (error.status != 0) {
       try {
         this.notifications.notify(error);
         console.error(error);
       } catch {
         this.notifications.notify('Job Canceling failed');
         console.error(error);
       }
   }
 }

 submit(app: Strudelapp, identity: Identity, batchinterface: BatchInterface, ftidentities?: Identity[], appparams?: any) {
  let headers = new HttpHeaders({
    'Authorization': `Bearer ${this.authorisationService.sessionToken$.value}`
  });
  //let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  this.notifications.notify('Submitting job');
  //let keys = JSON.stringify(this.authorisationService.getKeys());
  let ids = [];
  if (ftidentities != undefined) {
    for (let id of ftidentities) {
      ids.push(id.copy_skip_catalog())
    }
  }

  var o: Observable<any>;
  if (identity.site.submitcmdprefix !== null && identity.site.submitcmdprefix !== undefined) {
    o = this.runCommand(identity, identity.site.submitcmdprefix+batchinterface.submitcmd, {'input': app.startscript})
  } else {
    o = this.runCommand(identity, batchinterface.submitcmd, {'input': app.startscript})
  }
  o.subscribe(resp => { this.notifications.notify(null) },
    error => this.submissionError(identity, error));
 }


 cancel(job: Job) {

  // Cancel a job. Execute the defined cancel command on the cluster
  let cmd = job.identity.site.cancelcmd.replace("{jobid}",job.jobid);

  var o: Observable<any>;
  o = this.runCommand(job.identity, cmd, {});                  
  o.subscribe(
    () => { this.notifications.notify(null) },
    error => this.cancelError(error)
  );
 }

 public getAppInstance(job: Job, action: AppAction, apiserver: APIServer) {
  // Retrieve information about the running job. Usually this information includes the port and password/token
  // to access the job. Information about the node the job is running on is already known

   let cmd = action.paramscmd.replace("{jobid}",job.jobid);
   return this.runCommand(job.identity, cmd, {'bastion': job.identity.site.host, 'host': job.batch_host});
 }

 public createTunnel(job: Job, appinst: any, action: AppAction, apiserver: APIServer): Observable<string> {
   let username = job.identity.username;
   let loginhost = job.identity.site.host;
   let batchhost = job.batch_host;
   let port = appinst.port;
   let socket = appinst.socket;
   let params = new URLSearchParams;
   let headers = new HttpHeaders({
    'Authorization': `Bearer ${this.authorisationService.sessionToken$.value}`
  });
   //let headers = new HttpHeaders();
   job.connectionState = 2;

   let body = {
    'user': username,
    'host': batchhost,
    'bastion': loginhost,
    'appinst': appinst,
    'port': port,
    'socket': socket,
    'appname': job.appname
  }
   if ((action.notunnel != undefined) && (action.notunnel == true)) {
     return of('')
   }
   let options = { headers: headers, withCredentials: true};
   if (this.ipcService.useIpc) {

     return this.ipcService.createTunnel(body)
   } else {
    return this.http.post<string>(apiserver.tes+'/createtunnel', body, options)
   }
 }

 public getAppUrlLocal(job: Job, appinst: any, action: AppAction): Observable<any>{
/*    if (action.client.cmd !== undefined && action.client.cmd !== null) {
    var cmd: string = action.client.cmd[0];
    //return this.ipcService.launch(cmd)
    return this.ipcService.launch(job, appinst, action)
   } */
   console.log('in app urlLocal',appinst);
   let url="http://localhost:"+appinst['localport']+"/"+action.client.redir;
   for (let key in appinst) {
     if (appinst.hasOwnProperty(key)) {
      let value = appinst[key]
      url = url.replace("{"+key+"}",value)
     }
   }
   console.log('appUrlLocal will be',url);
   return of(url);
 }

 private getAppUrl(job: Job, appinst: any, action: AppAction, apiserver: APIServer): string {

  console.log('in app urlLocal',appinst);
  let url = apiserver.tws+'/'+action.client.redir;
  for (let key in appinst) {
    if (appinst.hasOwnProperty(key)) {
     let value = appinst[key]
     url = url.replaceAll("{"+key+"}",value)
    }
  }
  console.log('appUrlLocal will be',url);
  return url;

 }



 public contactUs(form: any) {
  let headers = new HttpHeaders({
    'Authorization': `Bearer ${this.authorisationService.sessionToken$.value}`
  });
   //let headers = new HttpHeaders();
   let options = { headers: headers, withCredentials: true};
   return this.backendSelectionService.apiserver.pipe(
     filter((v) => v !== undefined && v !== null),
     switchMap((apiserver) => this.http.post(apiserver.tes+'/contactus',{'data':form},options))
   )
 }




 public connect(job: Job, action: AppAction, appinst?: any) {
  // Connect ao an application this has multiple steps
  // 1. run a command to find info about ports and passwords for this instance
  // 2. create a tunnel
  // 3. Open a new window
  // Each of these is an http call returning an Observable with switchMaps to go to the next stage
  // Use tap to update the state of the "progress bar"


  job.connectionState=1
  // Can no connect till we know which api server we are using. Threfore the apiserver
  // is the start of the pipe
  // the API server is behaviorsubject so it should always fire immediately
  this.backendSelectionService.apiserver.pipe(
    filter((v) => v !== undefined && v !== null),
    switchMap((apiserver): Observable<[APIServer, string]> => 
            { return this.getAppInstance(job,action,apiserver)
              .pipe(
                catchError(err => this.handleAppInstanceError(job,action,err)),
                map((v)=>[apiserver,v]))
            } ),
    tap((_) => job.connectionState=2),
    switchMap(([apiserver,appinst]) => 
            { return this.createTunnel(job,appinst,action,<APIServer>apiserver)
              .pipe(
                tap((v)=> { console.log('created tunnel got data',v)}),
                catchError(err => this.handleTunnelError(job,action,err)),
                map((v) => 
                { 
                  if (v !== null && v.hasOwnProperty('localport')) {
                    appinst['localport'] = v['localport']; 
                  }
                  return [apiserver, appinst];
                }))
            } ),
    tap((_) => job.connectionState=3),

    map( ([apiserver, appinst]) => {
      let url = this.getAppUrl(job,appinst,action,<APIServer>apiserver);
      return [apiserver, appinst, url]
    }),

    tap((_) => job.connectionState=4),
  ).subscribe(([apiserver,appinst,url]) => { if (url !== null) {
                                         this.openWindow$.next( {'job':job, 
                                               'url': <string> url, 
                                               'usebasicauth':action.client.usebasicauth,
                                               'apiserver':apiserver,
                                           'action':action,'appinst':appinst});
                                     }
                                   job.connectionState=null},
    (err) => { job.connectionState = 0; this.handleError(job.identity, err)})
}

  public runCommand(identity: Identity, command: string, optional?: any): Observable<any> {

  // execute a remote command on the cluster. 
  // optional is a dictionary which can specify things like a basition
  let headers = new HttpHeaders({
      'Authorization': `Bearer ${this.authorisationService.sessionToken$.value}`
    });
  //let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  if (identity === undefined || identity === null) {
    return
  }
  let body = {
    'user': identity.username, 
    'host': identity.site.host,
    'cmd': command,
  }
  if (optional !== undefined && optional !== null) {
    body = { ...body, ...optional };
  }

  var o: Observable<any>;
  if (this.ipcService.useIpc) {
    body['tag'] = uuid();
    console.log('Im using tag',body['tag']);
    o = this.ipcService.runRemoteCommand(body)
  } else {
    o = this.http.post<any>(this.Base+'/remotecommand', body, options)
  }

  return o;
}

private handleError(identity: Identity, error: any) {
  console.log('in handelError');
  console.error(error);
}

private handleAppInstanceError(job: Job, action: AppAction, error: HttpErrorResponse): Observable<any> {
  console.error(error);
  console.log('in handleAppInstanceError');
  const msg: string = "The command " + action.paramscmd + " on host " + job.batch_host + " "
  if (job.identity.expiry > Date.now() && (error.hasOwnProperty("status") && error.status == 401)) {
    this.notifications.notify("It looks like the application is running slow. Please try again in a few minutes.");
    return throwError("app instance error, possibly pam_slurm_adopt");
  } 


  
  console.log('else strategy',job.identity.expiry, Date.now(),error.status);
  return this.handleGenericError(job.identity,error,msg);
}

private handleTunnelError(job: Job, action: AppAction, error: HttpErrorResponse): Observable<any> {
  console.error(error);
  console.log('in handle tunnel error');
  const msg: string = "Attempting to create an SSH tunnel to " + job.batch_host + " via " + job.identity.site.host;
  if (job.identity.expiry > Date.now() && (error.hasOwnProperty("status") && error.status == 401)) {
    this.notifications.notify("It looks like the application is running slow. Please try again in a few minutes.")
    return throwError("tunnel error, possible pam_slurm_adopt");
  } else {
    return this.handleGenericError(job.identity,error,msg);
  }
}

private unraw(str: string): string {
  return str.replace(/\\[0-9]|\\['"\bfnrtv]|\\x[0-9a-f]{2}|\\u[0-9a-f]{4}|\\u\{[0-9a-f]+\}|\\./ig, match => {
      switch (match[1]) {
          case "'":
          case "\"":
          case "\\":
              return match[1];
          case "b":
              return "\b";
          case "f":
              return "\f";
          case "n":
              return "\n";
          case "r":
              return "\r";
          case "t":
              return "\t";
          case "v":
              return "\v";
          case "u":
              if (match[2] === "{") {
                  return String.fromCodePoint(parseInt(match.substring(3), 16));
              }
              return String.fromCharCode(parseInt(match.substring(2), 16));
          case "x":
              return String.fromCharCode(parseInt(match.substring(2), 16));
          case "0":
              return "\0";
          default: // E.g., "\q" === "q"
              return match.substring(1);
      }
  });
}


private handleGenericError(identity: Identity,  error: HttpErrorResponse, msg: string): Observable<any> {

  console.error('handleGenericError');
  console.error(error);
  // If the API returned a error code, and a json object with a message, display that message
  var emsg: string = "";
  if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message') ) {
    emsg = error.error.message;
    this.notifications.notify(emsg);
    console.error('handleGenericError throwing the error again',emsg);
    return throwError(error);
  } 

  // If the API returuned an error code, and a string with the substring "failed with error message b" display that.
  var PATTERN: string ='failed with error message b'

  if (error.hasOwnProperty("error") && error.error.hasOwnProperty("detail")) {
    console.error('handleGenericError, found error.error.detail');
    var startidx: number = error.error.detail.indexOf(PATTERN);
    if (startidx !== -1) {
      console.error(error.error.detail.slice(startidx+PATTERN.length+1,error.error.detail.length-1));
      let rawstr = error.error.detail.slice(startidx+PATTERN.length+1,error.error.detail.length-1);
      let unrawstr = this.unraw(rawstr);
      this.notifications.notify(unrawstr);
      return throwError(error);
    }
  }
  console.error('handleGenericError, no error.error.detail');

  // If the API returned status 401 (unauthorized) and the identity has expired, refresh the identity
  if (identity.expiry !== null && identity.expiry < Date.now() && (error.hasOwnProperty("status") && error.status == 401)) {
    console.error('handleGenericError');
    this.notifications.notify("Your login has expired. Please log in again",   () => { this.authorisationService.refresh(); }  );
    return throwError(error);
  }


  // If the API returned any of THESE status code, and didn't give either a json message of a string message, display a generic message
  if (error.hasOwnProperty("status") && error.status == 500) {
    this.notifications.notify("The Strudel2 API server had an error.\n Please report this via the contact us link.\nThe error message was"+emsg);
    return throwError(error);
  }
  if (error.hasOwnProperty("status") && error.status == 400) {
    this.notifications.notify(msg + " unexpectedly returned an error message.\n Please report this via the contact us link.\nThe error message was\n" + emsg);
    return throwError(error);
  }
  if (error.hasOwnProperty("status") && error.status == 504) {
    this.notifications.notify(msg + " timed out. Is the application server OK?");
    return throwError(error);
  }
  if (error.error instanceof ErrorEvent) {
    this.notifications.notify("A networking error occured. Is your internet connection OK?")
    return throwError(error);
  } 

  if (error.hasOwnProperty('message')) {
    emsg = error.message;
    this.notifications.notify(emsg);
    console.error('handleGenericError throwing the error again',emsg);
    return throwError(error);
  }

  this.notifications.notify(error);
  return throwError(error);
}

}
