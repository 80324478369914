<div style="height: 100%; width: 100%; box-sizing: border-box; padding-top: 20px; background-color: var(--table-background-color);">
<mat-sidenav-container style="width: 100%; height:100%; background-color: var(--table-background-color);" autosize>
    <mat-sidenav #idSideNav mode="side" [opened]="(settingsService.menuToggle$ | async) && (settingsService.useMenu$ | async)" class="strudel-service-typography">
        <div class="full-height-sidebar-content">
            <div class="wrapper">
                <div class="scroll-container">
                    <nav class="sidenav-box">
                        <mat-list class="sidenav-box" style="border-style: none">
                            <mat-list-item>
                                <span class="sidenav-header"><h2 style="margin: 0px; margin-top: 0px; padding-top: 0px; text-align: left">Select a site</h2></span>
                            </mat-list-item>
                        </mat-list>
                        <div style="border-style: solid none none none ; border-width: 1px; border-color: var(--panel-border-color); box-sizing: border-box">
                        <div *ngIf="computeSitesService.appidentities$ | async as obs; else loading">
                        <mat-accordion style="width: 100%" [displayMode]="'flat'">
                            <div *ngFor="let id of appidentities" style="border-style: none none solid none; border-width: 1px; border-color: var(--panel-border-color) ">
                                <!--<mat-expansion-panel (afterExpand)="selectId(id)" (closed)="selectId(id)" style="width: 100%">-->
                                <mat-expansion-panel style="width: 100%; " [expanded]="identity === id" class="mat-elevation-z0">
                                    <mat-expansion-panel-header class="small-headers mat-body-2" style="margin: 0px">
                                        <mat-panel-title >
                                            <span matBadge="{{ countErrors((id.systemalerts | async), (id.accountalerts | async)) }}" 
                                                                    [matBadgeHidden]= "countErrors((id.systemalerts | async), (id.accountalerts | async)) == 0"  
                                                                    matBadgePosition="above before" 
                                                                    matBadgeColor="warn" 
                                                                    matBadgeOverlap="false" matBadgeSize="small"
                                                                    style="text-align: left" 
                                                                    class="sidenav-site-header small-headers">
                                                {{ id.displayName() }}
                                            </span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <mat-list class="small-headers mat-body-2" style="width: 100%">
                                        <mat-list-item>
                                            <button mat-button  class="mat-body-2" style="width: 100%; justify-content: flex-start;"
                                            [routerLink]="['/launch',id.displayName(),'accountinfo']"
                                            routerLinkActive="linkActive" #rla="routerLinkActive">
                                                Account Info
                                            </button>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                    
                                    <app-strudelapplist [applist]=id.appCatalogStatic [identity]="id" style="width: 100%"></app-strudelapplist>
                                    </mat-list>
                                </mat-expansion-panel>
                                <!--<div style="width: 100%; border-bottom: 1px solid var(--panel-border-color);"></div>-->
                            </div>
                        </mat-accordion>
                        </div><ng-template #loading>Loading...</ng-template>
                        </div>
                    </nav>
            </div>
        </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content  >
        <div class="full-height-main-content">
            <div class="wrapper">
                <div class="scroll-container">
                    <div *ngIf="computeSitesService.appidentities$ | async as obs; else loading" style="background-color: var(--table-background-color);">
                        <!--div style="display: flex; flex: 1; flex-direction:column; justify-content: flex-start; align-items: start;">-->
                        <!--style="overflow: scroll; box-sizing: border-box; width: 100%; height: 100%; padding-left: 5%; padding-right: 5%;"-->
                        <div style=" box-sizing: border-box; padding-left: 5%; padding-right: 5%;">
                            <div *ngIf="(app$ | async) == null">
                                <app-accountinfo [identity]="identity"></app-accountinfo>
                            </div>
                            <div *ngIf="identity !== undefined &&  identity !== null && app$.value !== undefined && app$.value !== null">
                                <app-launch-dialog [identity]="identity" [appSubject]="app$"></app-launch-dialog>
                                <app-joblist [identity]="identity" [app]="(app$ | async)"></app-joblist>
                            </div>
                        </div>
                    </div>
                    <ng-template #loading>Determining which sites you are logged into already ...</ng-template>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
</div>
