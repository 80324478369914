  <div class="warndiag" *ngIf="data.identity.site.name.toLowerCase().indexOf('mlerp')===-1">
  
  
  <div *ngIf="data.app !== undefined && data.app !== null">
  <h2>{{ data.app.name }} Running</h2>
  It looks like you already connected to {{ data.app.name }}<br>
  </div>
  <div *ngIf="data.app === undefined || data.app === null">
  <h2>Application Running</h2>
  It looks like you already connected to a Strudel2 Application<br>
  </div>
  Depending on which applications you are connecting to and which order you open the windows in, you may experience issues with the secure link from your computer to the Strudel2<br>
  For example if you open Jupyter Lab then a terminal Jupyter Lab will lose the secure tunnel. If you open the terminal app then the Jupyter Lab app this will be OK.
  <div fxLayout="row" fxLayoutAlign="space-between stretch" style="width: 100%">
  <button mat-button (click)="close(null)">OK, Got it!</button>
  </div>
  </div>

  <div class="warndiag" *ngIf="data.identity.site.name.toLowerCase().indexOf('mlerp')!==-1">
    <img src="./assets/images/mlerp-dragon.png" style="float:right; height: 200px ; width: 200px"/>

    <h2> Here be dragons</h2>
    It looks like you already connected to a Strudel2 Application<br>
    Depending on which applications you are connecting to and which order you open the windows in, you may experience issues with the secure link from your computer to the Strudel2<br>
    For example if you open Jupyter Lab then a terminal Jupyter Lab will lose the secure tunnel. If you open the terminal app then the Jupyter Lab app this will be OK.
    <div fxLayout="row" fxLayoutAlign="space-between stretch" style="width: 100%">
    <button mat-button (click)="close(null)">OK, Got it!</button>
  </div>
