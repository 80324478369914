import { Component, OnInit } from '@angular/core';
import {BackendSelectionService } from '../backend-selection.service';
import { BehaviorSubject } from 'rxjs';
import {OverlayContainer} from '@angular/cdk/overlay';
import { AuthorisationService } from '../authorisation.service';
import { ComputesitesService } from '../computesites.service';
import { Location } from '@angular/common';
import { SettingsService } from '../settings.service';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public selectedApiServer: any;
  private file: any;
  private config: any;
  public theme: string;
  public themes: any[] = [ {'name':'Light','value':'strudel-light-theme'},
                   {'name': 'Dark','value':'strudel-dark-theme'},
                   {'name': 'MLeRP','value':'strudel-mlerp-theme'},
                   {'name': 'GeRP','value':'strudel-gerp-theme'}
  ]
  public apiservers: any[]
  public selectedVal: string;
  constructor(
                public backendSelectionService: BackendSelectionService,
                public authService: AuthorisationService,
                public computeSitesService: ComputesitesService,
                public overlayContainer: OverlayContainer,
                public settingsService: SettingsService,
                private location: Location
    ) { 
      this.selectedApiServer = null;
      this.backendSelectionService.apiserver.subscribe((s) => this.selectedApiServer = s);
      this.apiservers = []
      this.backendSelectionService.apiservers.subscribe((l) => this.apiservers = l);
  }

  ngOnInit() {

    for (let t of this.themes) {
      if (t.value == this.settingsService.theme$.value) {
        this.theme = t;
      }
    }
    this.selectedVal = this.settingsService.useMenu$.value ? "true":"false";
  }

  loadConfig(event) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
          try {
            this.config = JSON.parse(e.target.result);
              
              if ('computesites' in this.config) {
                  this.computeSitesService.storeLocalComputeSites(this.config['computesites']);
                  console.log('saving compute site',this.config['computesites']);
              }
              if ('authz' in this.config) {
                  this.authService.storeLocalAuthZ(this.config['authz'])
              }

          } catch {
          }
      };
      reader.readAsText(event.target.files[0]);
  }


  resetConfig() {
      this.computeSitesService.removeLocalComputeSites();
      this.authService.removeLocalAuthZ()
  }

  selectTheme(event) {
    this.settingsService.setTheme(event.value);
  }

}
