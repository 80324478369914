import { Component, OnInit, Inject } from '@angular/core';
import { AuthorisationService } from '../authorisation.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';


@Component({
  selector: 'app-logoutdialog',
  templateUrl: './logoutdialog.component.html',
  styleUrls: ['./logoutdialog.component.css']
})
export class LogoutdialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogoutdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthorisationService) {
   }

  ngOnInit() {
  }

  onLogout() {
    this.authService.logout();
    this.dialogRef.close();
  }
  onCancel() {
    this.dialogRef.close();
  }

}
