import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators'
import { AuthorisationService } from '../authorisation.service';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-keygen',
  templateUrl: './keygen.component.html',
  styleUrls: ['./keygen.component.css']
})
export class KeygenComponent implements OnInit, OnDestroy {

//  private keygenWorker: Worker

  constructor(private route: ActivatedRoute, 
    private authService: AuthorisationService,
    private settingsService: SettingsService)
  { 

  }

  ngOnInit() {
    console.log('entered keygen component',this.settingsService.theme$.value);
    console.log(this.route);
    //this.initPipelines();
    this.route.fragment.pipe(filter((v) => ( v !== null && v !== undefined ))).subscribe((v) => { console.log('keygen is updating fragment',v) ; this.authService.updateFragment(v)});
  }

  ngOnDestroy() {
    //this.keygenWorker.terminate()
  }
 
  }
