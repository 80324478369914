    <div *ngFor="let app of applist" >
        <!--<mat-list-item *ngIf="app.startscript != null" style="width: 100%">-->
        <div *ngIf="app.name != 'divider'">
            <mat-list-item style="width: 100%">
                <button class="mat-body-2" mat-button style="width: 100%; justify-content: flex-start;"
                                        [routerLink]="['/launch',identity.displayName(),app.name]"
                                        routerLinkActive="linkActive" #rla="routerLinkActive">
                {{ app.name }}</button>
            </mat-list-item>
            <div *ngIf="app.applist != null">
                <mat-list-item>
                <app-strudelapplist [applist]=app.applist [identity]=identity (appChange)="select($event)"></app-strudelapplist>
                </mat-list-item>
            </div>
        </div>
        <div *ngIf="app.name == 'divider'">
            <mat-divider></mat-divider>
        </div>
           
    </div>
