import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { AuthorisationService } from '../authorisation.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthorisationService,
    private router: Router,
  ) {
 }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
    this.authService.loggedInAuthZ$.subscribe(() => this.router.navigate(['/login']));
  }

}
