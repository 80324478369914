import { Component, OnInit, Input } from '@angular/core';
import { JsonFormsAngularService, JsonFormsControl } from '@jsonforms/angular';
import { ControlProps } from '@jsonforms/core';
import { OwnPropsOfControl, RankedTester, Actions, composeWithUi, ControlElement } from '@jsonforms/core';


@Component({
  selector: 'app-jsonforms-enum-render',
  templateUrl: './jsonforms-enum-render.component.html',
  styleUrls: ['./jsonforms-enum-render.component.css']
})
export class JsonformsEnumRenderComponent extends JsonFormsControl  implements OnInit{

  options: string[];
  selected: string;
  dataAsString: string;
  props: OwnPropsOfControl;


  constructor(service: JsonFormsAngularService) {
    super(service);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    // this.props = this.getOwnProps();
    // console.log('props:',this.props);
    console.log('options:',this.options); 
  }

  public mapAdditionalProps(props: ControlProps) {
    super.mapAdditionalProps(props);
    this.props = props;
    this.dataAsString = JSON.stringify(props.data, null, 2);
    if (props.schema.enum) {
      this.options = props.schema.enum;
    } else {
      this.options = [props.schema.const];
    }
    this.selected = props.data as string;
  }


  onSelect(ev: any) {
    this.selected = ev;
    const path = composeWithUi(this.uischema as ControlElement, this.path);
    this.jsonFormsService.updateCore(
      Actions.update(path, () => ev)
    );
    this.triggerValidation();
  }



  // protected getOwnProps(): OwnPropsOfControl {
  //   return {
  //     ...super.getOwnProps(),
  //   };
  // }

}

