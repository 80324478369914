<!-- <router-outlet class="fill-remaining-space"></router-outlet> -->
<!--<div *ngIf="(tesService.theme | async) == 'strudel-dark-theme'" class="strudel-dark-theme" style="width: 100vw; height: 100vh">
<router-outlet></router-outlet>
</div>
<div *ngIf="(tesService.theme | async) == 'strudel-light-theme'" class="strudel-light-theme" style="width: 100vw; height: 100vh">
<router-outlet></router-outlet>
</div> -->

<!--<app-launcher></app-launcher>-->
<div style="height: 100%; width: 100%; display: flex; flex-direction: column; box-sizing: border-box;" class="mat-typography">
<mat-toolbar style="flex: 0; height: 80px" class=mat-header color="primary">
              <mat-toolbar-row style="display: flex; flex-direction: row ;height: 80px">
                    <button mat-icon-button *ngIf="settingsService.useMenu$ | async" aria-label="toggle menu" (click)=toggleMenu()><mat-icon>menu</mat-icon></button>
                    <span class="empty-spacer"></span>
                    <span class="mat-headline-4">{{ envLoaderService.name }}</span>
                    <span style="flex: 1"></span>
                    <span class="fill-horizontal-space"></span>
                    <button mat-button routerLink="/launch" [routerLinkActiveOptions]="{ exact: false }" routerLinkActive #rlahome="routerLinkActive" [disabled]="rlahome.isActive || ((authService.loggedin$ | async) == 0)"><mat-icon>home</mat-icon>Home</button>
                    <button mat-button routerLink="/login"  routerLinkActive #rlalogout="routerLinkActive" [disabled]="rlalogout.isActive || ((authService.loggedout$ | async) == 0)"><mat-icon>exit_to_app</mat-icon>Log in</button>
                    <button mat-button routerLink="/logout" routerLinkActive #rlalogin="routerLinkActive" [disabled]="rlalogin.isActive || ((authService.loggedin$ | async ) == 0)"><mat-icon>logout</mat-icon>Log out</button>
                </mat-toolbar-row>
</mat-toolbar>
<div style="flex: 1; overflow:hidden;" class="main-content">
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>

<!-- <mat-sidenav-container style="position: fixed; height: 100%; min-height: 100%; width: 100% ; min-width: 100%;" class="side-nav">
    <mat-sidenav closed >
    </mat-sidenav>
    <mat-sidenav-content >
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container> -->
</div>
<div style="flex: 0">
<mat-toolbar class="footer" style="display: flex; flex-direction: row; ">
    <span style="flex: 1"></span>
        <button mat-button class="button-link" disableRipple="true" routerLink="/about-us">About Us</button>
        <button *ngIf="envLoaderService.docs_url" mat-button class="button-link" disableRipple=true (click)="openDocs()">Documentation</button>
        <button mat-button class="button-link" disableRipple="true" routerLink="/settings">Settings</button>
        <button mat-button class="button-link" disableRipple=true routerLink="/contact-us">Contact Us</button>
    <span style="flex: 1"></span>
</mat-toolbar>
</div>
</div>
