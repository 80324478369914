import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-modaldialog',
  templateUrl: './modaldialog.component.html',
  styleUrls: ['./modaldialog.component.css']
})
export class ModaldialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModaldialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
) {
   }

  ngOnInit() {
    console.log('created close dialog');
    console.log(this.data);
  }

  close(rv) {
    this.dialogRef.close(rv);
  }

    

}
