import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Strudelapp} from '../strudelapp';
import { Identity } from '../identity';
import { repeat } from 'rxjs/operators';
import { BatchInterface} from '../batchinterface';
import { timer } from 'rxjs/observable/timer';
import { LaunchDialogComponent } from '../launch-dialog/launch-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import { TesService } from '../tes.service';




@Component({
  selector: 'app-strudelapplist',
  templateUrl: './strudelapplist.component.html',
  styleUrls: ['./strudelapplist.component.css']
})
export class StrudelapplistComponent implements OnInit {
  @Input() applist: Strudelapp[];
  @Input() identity: Identity;
  @Output() appChange: EventEmitter<Strudelapp>;
  @Input() app: Strudelapp;


  constructor(
    private tesService: TesService,
    private domSanitizer: DomSanitizer) {
    this.appChange = new EventEmitter<Strudelapp>();
  }

  ngOnInit() {
  }

  select(app: Strudelapp) {
    console.log('strudel-applist emits');
    this.appChange.emit(app);
  }

}
