import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { DomSanitizer} from '@angular/platform-browser';
import { EnvLoaderService } from '../env-loader.service';



@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})


export class AboutUsComponent implements OnInit {
  constructor (    private domSanitizer: DomSanitizer, private envLoaderService: EnvLoaderService
    ) {}
  public slides: any;
  ngOnInit() {
    if (this.envLoaderService.about_slides !== null ) {
      this.slides = this.domSanitizer.bypassSecurityTrustResourceUrl(this.envLoaderService.about_slides);
    } else {
      this.slides = null;
    }
    console.log('slides set to',this.slides);

  }

}
