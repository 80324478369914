import { Component } from '@angular/core';

@Component({
  selector: 'app-oidccallback',
  templateUrl: './oidccallback.component.html',
  styleUrls: ['./oidccallback.component.css']
})
export class OidccallbackComponent {

}
