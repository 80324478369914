import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LauncherComponent } from './launcher/launcher.component';
import { KeygenComponent } from './keygen/keygen.component';
import { JoblistComponent } from './joblist/joblist.component';

import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {SettingsComponent} from './settings/settings.component';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { OurServicesComponent } from './ourservices/ourservices.component';
import { ContactUsComponent } from './contactus/contactus.component';
import { NoaccountComponent } from './noaccount/noaccount.component';
import { OidccallbackComponent } from './oidccallback/oidccallback.component';

// import { TokenextractorComponent } from './tokenextractor/tokenextractor.component';


const routes: Routes = [
  { path: '', redirectTo: 'launch', pathMatch: 'full'},
  //{ path: 'launch', component: JoblistComponent},
  { path: 'launch', component: LauncherComponent},
  { path: 'launch/:site', component: LauncherComponent},
  { path: 'launch/:site/:app', component: LauncherComponent},
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'settings', component: SettingsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'our-services', component: OurServicesComponent },
  { path: 'contact-us', component: ContactUsComponent },
  //  { path: 'finishlaunch', component: LauncherComponent},
  //{ path: 'cancellaunch', component: LauncherComponent},
  { path: 'sshauthz_callback', component: KeygenComponent},
  { path: 'callback', component: OidccallbackComponent},
  { path: 'sshauthz_callback*', component: KeygenComponent},
  { path: 'noaccount/:site', component: NoaccountComponent},
  { path: '**', component: LauncherComponent},
  //{ path: 'shareconnect', component: ShareconnectComponent }


  // { path: 'sshauthz_callback', component: LauncherComponent}


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)

  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }
