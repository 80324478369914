import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { repeat } from 'rxjs/operators';
import { timer } from 'rxjs/observable/timer';

import {Strudelapp} from './strudelapp';
import { TesService } from './tes.service';
import { Identity } from './identity';
import { BatchInterface} from './batchinterface';
import { LaunchDialogComponent } from './launch-dialog/launch-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import{ BehaviorSubject } from 'rxjs';


// This service is responsible for starting applications
// Along the way it opens new windows/tabs to
/** This service is repsonible for starting applications
Along the way it opens new windows/tabs to determine
    1) Any configuration parameters for the application itself (if the app suports This
    2) The batch command to submit the application to a cluster (if any)
The windows are "watched" for URL changes and parameters are extracted from the
query string
*/


@Injectable({
  providedIn: 'root'
})
export class SubmitAppService {
  private configwindow: any;
  private appparams: string;
  // private submitcmd: string;


  private windowWatcher: any;
  private watchingForSubmit: boolean;
  public configApp: BehaviorSubject<any>;
  public appData: BehaviorSubject<any>;
  public submitcmd: BehaviorSubject<string>;
  public identity: BehaviorSubject<Identity>;
  public app: BehaviorSubject<Strudelapp>;
  public readyToLaunch: BehaviorSubject<Boolean>;


  constructor(private tesService: TesService,
              public dialog: MatDialog,
              private domSanitizer: DomSanitizer,
              ) {
    // this.appparams = null;
    // // this.submitcmd = null;
    // this.watchingForSubmit = false;
    this.app = new BehaviorSubject(null);
    this.appData = new BehaviorSubject(null);
    this.submitcmd = new BehaviorSubject(null);
    this.identity = new BehaviorSubject(null);
    this.readyToLaunch = new BehaviorSubject(false);
    this.app.subscribe(() => this.clearInfo());
    this.identity.subscribe(() => this.clearInfo());
    this.submitcmd.subscribe(() => this.canILaunch());
    this.appData.subscribe(()=> this.canILaunch());
  }

  launch() {
    let bi = new BatchInterface();
    bi.submitcmd = this.submitcmd.value;
    if (this.appData.value != null){ 
        //      let appparams = JSON.stringify(this.appData.value);
      let appparams = this.appData.value;
      this.tesService.submit(this.app.value,this.identity.value,bi,appparams);
    } else {
        this.tesService.submit(this.app.value,this.identity.value,bi);
    }
  }

  clearInfo() {
      this.appData.next(null);
      this.submitcmd.next(null);
  }

  canILaunch() {
    if (this.submitcmd.value != null) {
        if (this.app.value.url == null || this.appData.value != null ) {
            this.readyToLaunch.next(true);
            return;
        } 
    }
    this.readyToLaunch.next(false);
  }

}
