import { Component, OnInit } from '@angular/core';
import { JsonFormsAngularService, JsonFormsControl } from '@jsonforms/angular';
import { OwnPropsOfControl, RankedTester, Actions, composeWithUi, ControlElement } from '@jsonforms/core';


import { FormControl } from '@angular/forms';
import { parse } from 'path';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.css']
})
export class DurationComponent extends JsonFormsControl   implements OnInit {
  label: string = 'Duration';
  field: FormControl = new FormControl("");
  numstring: string = null;
  errormsg: string = null;


  constructor(service: JsonFormsAngularService) {
    super(service);
  }

  ngOnInit(): void {
    super.ngOnInit();
    //this.field.valueChanges.subscribe((value) => this.updateJsonForms(value))

  }

  private removeNonNumericChars(value: string): string {
    return value.replace(/\D/g, '');
  }

  private separateDHMS(value: string): [number, number, number, number] {
    const days = parseInt(value.slice(0,-6));
    const hours = parseInt(value.slice(-6,-4));
    const minutes = parseInt(value.slice(-4,-2));
    const seconds = parseInt(value.slice(-2));
    return [days, hours, minutes, seconds]
  }

  private insertChar(original: string, char: string, position: number): string {
    return original.slice(0, position) + char + original.slice(position);
}




  public onKeyPress(event: any) {
    var numstring = this.removeNonNumericChars(this.field.value);
    if (event.key === 'Backspace' || event.key === 'Delete') {
      numstring = numstring.slice(0, -1);
    }
    if (!isNaN(Number(event.key))) {
      numstring = numstring+event.key;
    }

    //var seconds = this.durationStringToSeconds(numstring);
    //var ds = this.secondsToDurationString(seconds);
    var ds = numstring.padStart(8, '0').slice(-8);
    ds = this.insertChar(ds, 'd ', -6);
    ds = this.insertChar(ds, 'h ', -4);
    ds = this.insertChar(ds, 'm ', -2);
    ds = this.insertChar(ds, 's', ds.length);
    this.field.setValue(ds);
    event.preventDefault();
    this.updateJsonForms(ds);
  }

  updateJsonForms(durationstring: string) {
    const path = composeWithUi(this.uischema as ControlElement, this.path);
    var seconds = this.durationStringToSeconds(durationstring);
    this.jsonFormsService.updateCore(Actions.update(path, () => (seconds)));
    this.triggerValidation();
  }


  secondsToDurationString(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const reminaingSeconds: number = seconds % 60;
    const hours: number = Math.floor(minutes / 60);
    const remainingminutes: number = minutes % 60;
    const days = Math.floor(hours / 24);
    const remaininghours = hours % 24;
    const daysString = String(days).padStart(1, '0');
    const hoursString = String(remaininghours).padStart(2, '0');
    const minutesString = String(remainingminutes).padStart(2, '0');
    const secondsString = String(reminaingSeconds).padStart(2, '0');

    return `${daysString}d ${hoursString}h ${minutesString}m ${secondsString}s`;
  }

  durationStringToSeconds(duration: string): number {
    const ds = this.removeNonNumericChars(duration);
    //const [days, hours, minutes, seconds] = this.parseDuration(ds);
    const [days, hours, minutes, seconds] = this.separateDHMS(ds);
    return days*24*60*60 + hours*60*60 + minutes*60 + seconds;
  }


  mapAdditionalProps(props: any) {
    super.mapAdditionalProps(props);
    if (props.errors !== undefined && props.errors.length > 0) {
      this.field.setErrors({'incorrect': true});
      if (props.errors.indexOf('<=') > -1) {
        var timelimit = parseInt(this.removeNonNumericChars(props.errors));
        this.errormsg = `Duration must be less than or equal to ${this.secondsToDurationString(timelimit)}`;
      } else if (props.errors.indexOf('>=') > -1) {
        var timelimit = parseInt(this.removeNonNumericChars(props.errors));
        this.errormsg = `Duration must be greater than or equal to ${this.secondsToDurationString(timelimit)}`;
      }
    } else {
      this.field.setErrors(null);
      this.errormsg = null;
    }
    if (this.field.value === undefined || this.field.value === null || this.field.value === '') {
      this.field.setValue(this.secondsToDurationString(props.data));
    }
    //this.field.setValue(this.secondsToDurationString(props.data));
    //this.field.setValue(props.data);
  }

  numericOnly(event): boolean {
    return !isNaN(Number(event.key)) && event.key !== ' ';
  }

}
