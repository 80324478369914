import {BehaviorSubject} from 'rxjs';

export class Computesite {
  url: string;  // The URL runs a web service to help construct sbatch commands
                // Infact the URL should return the entire interface for
                // submit stat and cancel
  name: string; // Human readable name
  host: string; // Login host
  dtn: string;
  dtnport: string;
  lscmd: string;
  cafingerprint: string;  // Certificates contain a CA fingerprint. We use this
                          // to figure out which compute site a certificate is valid
                          // for
  
  appCatalog: [];
  appCatalogUri: string;
  appCatalogCmd: string;
  internalfirewall: boolean; // Does a firewall exist within the site necessitating an extra level of ssh tunnel
  cancelcmd: string;
  statcmd: string;
  userhealth: string;
  cacheturis: string[];
  contact: string;
  submitcmdprefix: string;
}


export class Health {
  stat: string;
  msg: string;
  type: string;
  data: any;
  title: string;
}
