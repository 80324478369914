import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import {BackendSelectionService } from '../backend-selection.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {OverlayContainer} from '@angular/cdk/overlay';
import { AuthorisationService } from '../authorisation.service';
import { ComputesitesService } from '../computesites.service';
import { Computesite } from '../computesite';
import { Location } from '@angular/common';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Subscription, pipe, combineLatest } from 'rxjs';
import {filter} from 'rxjs/operators';
import {map as rxjsmap, tap} from 'rxjs/operators';
import { UntypedFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TesService} from '../tes.service';
import {NotificationsService} from '../notifications.service';
import { MatSelectChange } from '@angular/material/select';
import { EnvLoaderService } from '../env-loader.service';


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
})
export class ContactUsComponent implements OnInit {

  private sub: Subscription;
  public contacts: String[];
  public tofield: String[];
  public contactForm: any
  public filteredContacts$: Observable<any>;
  public contacts$: Observable<any>;
  public tofield$: BehaviorSubject<any[]>;

  @ViewChild('auto', { read: ElementRef }) matAutocomplete: MatAutocomplete;
  @ViewChild('contactInput', { read: ElementRef }) contactInput: ElementRef<HTMLInputElement>; 
  @ViewChild('namefield', { read: ElementRef }) namefield: ElementRef<HTMLInputElement>; 
  constructor(private formBuilder: UntypedFormBuilder, private computeSitesService: ComputesitesService, private tesService: TesService, private notifications: NotificationsService, private envLoaderService: EnvLoaderService) {
    let defform = {'name':[], 'email':[], 'message':[], 'to':[], 'contactCtrl': [], 'subject':[]}
    this.contactForm=this.formBuilder.group(defform); 
    this.tofield$ = new BehaviorSubject([]);
    this.contacts$=combineLatest([this.computeSitesService.computesites,this.tofield$]).pipe(filter(([ids,to]) => ids!== undefined && ids!== null),
      rxjsmap(([ids,to]) => {return [this.contact_map(ids),to]}),
      rxjsmap(([ids,to]) => ids.filter((el) => { return to.indexOf(el) == -1 && el != null } )));
  }


  ngOnInit() {
  }


  ngOnDestroy() {
  }

  contact_map(sites: Computesite[]) {
    return  this.envLoaderService.contact.concat(sites.map((site) => site.contact));
  }


  sendmail() {
    let to = this.tofield$.value;
    let email = this.contactForm.controls.email.value;
    let name = this.contactForm.controls.name.value;
    let subject = this.contactForm.controls.subject.value;
    let message = this.contactForm.controls.message.value;
    let data = {'to':to, 'email':email, 'name':name,'subject':subject, 'message': message};

    this.tesService.contactUs(data).subscribe((r) => this.notifications.notify("Message sent"), (e)=> { console.error(e) ; this.notifications.notify("Failed to send message")});
  }

  compareFn(o1: any, o2: any): boolean {
    return false
  }

  selectionChanged(event: MatSelectChange): void {
    let tofield = this.tofield$.value;
    tofield.push(event.value);
    this.tofield$.next(tofield);
    this.contactForm.controls.contactCtrl.setValue(null);
    this.namefield.nativeElement.focus();
  }

  remove(contact: string): void {
    let tofield = this.tofield$.value;
    const index = tofield.indexOf(contact);
    if (index >= 0) {
      tofield.splice(index, 1);
    }
    this.tofield$.next(tofield);
  }

}
