import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { delay } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';
import { Renderer2} from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { repeat } from 'rxjs/operators';

import { Strudelapp } from '../strudelapp';
import { TesService } from '../tes.service';
import { Job } from '../job';
import { Identity } from '../identity';
import { AuthorisationService } from '../authorisation.service';
import { JobsService } from '../jobs.service';



@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.css']
})

export class JoblistComponent implements OnInit {
  @Input() identity: Identity;
  @Input() app: Strudelapp;
  private sub: Subscription;
  public joblist: Job[];


  constructor(private tes: TesService, private authService: AuthorisationService, private jobsService: JobsService ) {
  }

  public ngOnInit(): void {
    //this.sub = timer(5000).pipe(repeat()).subscribe( () => this.getJobs() );
    this.jobsService.setId(this.identity);
    this.sub = this.jobsService.jobs$.subscribe((jobs) => { this.updateJoblist(jobs, this.identity)});
  }

 updateJoblist(jobquery: Job[], identity: Identity) {
   var joblist: Job[] = []
   var qjobids: any[] = [];
   var cjobids: any[] = [];
   var j: Job;
   var newjob: Job;
   var idx: number;

   // get a list of the jobids in the job query
   if (jobquery === null || jobquery === undefined) {
     return
   }
   for (j of jobquery) {
       qjobids.push(j.jobid);
   }
   if (this.joblist !== undefined) {
     for (j of this.joblist) {
       cjobids.push(j.jobid);
     }
     // any jobs that we saw on a previous query, update the Job objects (don't use new objects)
     for (j of this.joblist) {
         idx = qjobids.indexOf(j.jobid)
         if (idx != -1) {
               // These values in the job may change, but we need to keep using the old object
               j.state  = jobquery[idx].state;
               j.endtime = jobquery[idx].endtime;
               j.batch_host = jobquery[idx].batch_host;
               joblist.push(j)
         }
     }
   }
   // any jobs that were returned by our query hat we have never seen before, push them onto joblist
   for (j of jobquery) {
       idx = cjobids.indexOf(j.jobid)
       if (idx == -1) {
           joblist.push(j);
       }
   }
       
   // any jobs in the joblist that we don't know which application they arem try to figure it out
   for (j of joblist) {
     if (j.app === undefined || j.app == null) {
       j.app = Strudelapp.getApp(j.appname,identity.appCatalogStatic);
     }
     if (j.identity == undefined) {
        j.identity = identity;
     }
     if (j.connectionState == undefined || j.connectionState == -1) {
         j.connectionState = 0;
     }
   }
   // Sort the joblist so jobs are descending
   this.joblist = joblist.sort((a,b) =>  (a.jobid < b.jobid)? 1:-1);
 }

  public ngOnDestroy(): void {
    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
  }

  public getAppName(): string { 
    // default name if this function fails to get the app name
    var appName: string = "job";
    if (this.app.name !== undefined) {
      // make appName a plural for display purposes
      // if character ends with "s" already a plural
      appName = this.app.name;
      
      if (appName.substr(appName.length - 1) !== 's') {
        appName = appName + 's';
      }
    }
    
    return appName.toUpperCase();
  }

}
