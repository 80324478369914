<div class="other-component" style="display: flex; flex-direction: row; justify-content: space-around">
<div style="flex-direction: column" class="content">
    <div class="heading"><h1>Log out</h1></div>
    <mat-divider></mat-divider>
    <div>&nbsp;</div>
    <p>Are you sure you want to logout from strudel?</p>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
        <button mat-flat-button color=primary class="big-button" (click)="logout()">Logout</button>
        <button mat-flat-button color=primary class="big-button" routerLink="/launch">Cancel</button>
    </div>
</div>
</div>

