import { Injectable } from '@angular/core';
import { TesService } from './tes.service';
import { ComputesitesService } from './computesites.service';
import { AuthorisationService } from './authorisation.service';
import { Job } from './job';
import { Identity } from './identity';
import { Subscription, interval, pipe, Observable, throwError, of, ReplaySubject } from 'rxjs';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { repeatWhen, delay, tap, catchError, takeUntil, startWith, switchMap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';


@Injectable({
  providedIn: 'root'
})
export class JobsService {
  public jobs$: BehaviorSubject<Job[]>;
  private tsub: Subscription;
  private id: Identity;
  private cancelRequests$: Subject<boolean>;


  constructor(private tesService: TesService, private authService: AuthorisationService, private notifications: NotificationsService ) { 
    this.jobs$ = new BehaviorSubject([]);
    this.cancelRequests$ = new Subject<boolean>();
  }

  public setId(id: Identity) {
    this.id = id;
    this.cancelRequests$.next(true);
    this.refreshJobs()
  }

  public cancelJobList() {
    this.cancelRequests$.next(true);
  }

  public refreshJobs() {
    if (this.id !== undefined && this.id !== null) {
      var query$: Observable<Job[]>;
      query$ = this.tesService.runCommand(this.id, this.id.site.statcmd)

      interval(5000)
      .pipe(
        startWith(0),
        takeUntil(this.cancelRequests$),
        switchMap((v) => query$),
        catchError((err) => this.networkError(err)),
        catchError((err) => this.getJobsErrorHandler(err, this.id.site.statcmd, this.id))
      )
      .subscribe(
        (qjobs) => this.jobs$.next(<Job[]>qjobs),
        (error) => this.getJobsError(error,this.id)
      );

    }

  }

  public networkError(error): Observable<any> {
    if (error.hasOwnProperty('status') && error.status == 0) {
      this.notifications.notify("A network error occurred. Are you connected to the internet?")
      return of([])
    } 
    return throwError(error);
  }

  public getJobsErrorHandler(error, cmd: string, identity: Identity): Observable<any> {
    var emsg: string = "";
    if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message') ) {
      emsg = error.error.message;
    } else {
      emsg = error.message;
    }
    if (identity.expiry < Date.now() && (error.hasOwnProperty("status") && error.status == 401)) {
      console.error('login expired while refreshing job list expiry',identity.expiry,'current time',Date.now() );
      this.cancelRequests$.next(true);
      this.notifications.notify("Your login has expired. Please log in again",   () => { this.authService.refresh() }  );
      return throwError(error);
    }
    if ((error.hasOwnProperty("status") && error.status == 401)) {
      this.cancelRequests$.next(true);
      this.notifications.notify("Your login has expired. Please log in again",   () => { this.authService.refresh() }  );
      return throwError(error);
    }
  
    if (error.hasOwnProperty("status") && (error.status == 503 || error.status == 500)) {
      //this.notifications.notify("The Strudel2 API server had an error.\n Please report this via the contact us link.\nThe error message was"+emsg);
      this.notifications.notify("Unable to retrieve a list of jobs for "+identity.site.name);
      return of([]);
    }
    if (error.hasOwnProperty("status") && error.status == 400) {
      this.notifications.notify(cmd + " unexpectedly returned an error message.\n Please report this via the contact us link.\nThe error message was\n" + emsg);
      return throwError(error);
    }

    if (error.hasOwnProperty("status") && error.status == 504) {
      this.notifications.notify("The Server timed out while retrieving a list of running jobs. Is the application server OK?");
      return of([]);
    }
    if (error.error instanceof ErrorEvent) {
      this.notifications.notify("A networking error occured. Is your internet connection OK?")
      //return throwError(error);
      // continue polling if the network is temporarily disconnected.
      return of([]);
    } 
  }

  public getJobsError(error,identity: Identity) {
    console.error(error);
    //this.notifications.notify("Unable to retrieve a list of running jobs.\nThis is probably an error on our end.\nPlease report what you were doing via the contact us link");
    //this.cancelRequests$.next(true);
    //this.authService.updateAgentContents().subscribe((_) => {return});
  }

}
