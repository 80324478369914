<div class="other-component" fxLayout="row" fxLayoutAlign="space-around">
 <div fxLayout="column" class="content">
     <div class="heading"><h1>No Account</h1></div>
     <mat-divider></mat-divider>
     <div>&nbsp;</div>

     <p *ngIf="site !== null">It looks like you authenticated succesfully but you don't have an account with a matching email on {{ site.name }}</p>
     <p *ngIf="site !== null" [innerHTML]="site.desc"></p>
 </div>
</div>

