import { Component } from '@angular/core';
import { TesService } from './tes.service';
import { AuthorisationService} from './authorisation.service';
import { ComputesitesService} from './computesites.service';
import { IpcService } from './ipc.service';
import {BehaviorSubject} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Computesite, Health } from './computesite';
import {SettingsService } from './settings.service';
import {BrowserWindowService} from './browser-window.service';
import {OverlayContainer} from '@angular/cdk/overlay';
import {NotificationsService} from './notifications.service';
import {ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { OAuthService, OAuthSuccessEvent, AuthConfig } from 'angular-oauth2-oidc';
import { EnvLoaderService } from './env-loader.service';





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Strudelv2';
  private snackBarRef: any;
  private currentroute: string = '';

  constructor(private tesService: TesService,
              public authService: AuthorisationService,
              private computeSitesService: ComputesitesService,
              public settingsService: SettingsService,
              private browserWindowService: BrowserWindowService,
              public snackBar: MatSnackBar,
              private overlayContainer: OverlayContainer,
              private route: ActivatedRoute,
              private router: Router,
              private notifications: NotificationsService,
              private ipcService: IpcService,
              private oauthService: OAuthService,
              public envLoaderService: EnvLoaderService
             ) {


              // const googleAuthConfig: AuthConfig = {
              //   // Url of the Identity Provider
              //   issuer: 'https://accounts.google.com',
              
              //   // URL of the SPA to redirect the user to after login
              //   //redirectUri: window.location.origin + '/index.html',
              //   redirectUri: window.location.origin + '/callback',
              //   //redirectUri: window.location.origin,
              //   //redirectUri: 'https://localhost:4200/index.html',
            
              //   // URL of the SPA to redirect the user after silent refresh
              //   silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
              
              //   // The SPA's id. The SPA is registerd with this id at the auth-server
              //   clientId: '110259548177-q6358lhnkp8hks174d9du445tv25rc4q.apps.googleusercontent.com',
              //   //clientId: '704184648951-updt0p4kfanrfqhakhep0tqod33b7n83.apps.googleusercontent.com',
              //   //dummyClientSecret: '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',
              //   //responseType: 'code',
              //   strictDiscoveryDocumentValidation: false,
              
              //   // set the scope for the permissions the client should request
              //   // The first three are defined by OIDC. The 4th is a usecase-specific one
              //   scope: 'openid profile email',
              
              //   showDebugInformation: true,
              
              //   sessionChecksEnabled: true,
              // };
        
              let authservice_str = window.sessionStorage.getItem('oidc_authserver')
              if (authservice_str) {
                console.log('initalising autservice based on last value in sessionStorage');
                let authservice = JSON.parse(authservice_str);
                this.oauthService.configure(authservice);
                this.oauthService.loadDiscoveryDocumentAndTryLogin();
              } else {

              }
              this.oauthService.events.subscribe((v) => console.error('oauth event',v));
              this.oauthService.events
                .pipe(filter((e) => e.type === 'token_received'))
                .subscribe((_) => this.oauthService.loadUserProfile());
              this.oauthService.events.subscribe((e) => { console.log(Date(),e);});
   };

  ngOnInit() {
    const isdefined = filter(v  => v !== null && v !== undefined)
    this.notifications.getMsg().subscribe(msg => this.displayMessage(msg));
    this.settingsService.theme$.subscribe((v) => this.setTheme(v));
    this.settingsService.getTheme();

    //this.ipcService.register_oauth2_redirect((url) => { this.route_to_keygen(url)} );





  }

  openDocs() {
    window.open(this.envLoaderService.docs_url);
  }

  route_to_keygen(url: string) {
    //console.log('calling back from oauth', args);
    //var url: string = args[0][0][0];
    console.log('url is', url);
    let fragment = "#" + url.split('#')[1];
    console.log('app.component is updating fragment',fragment);
    this.authService.updateFragment(fragment);
    this.router.navigate(['/sshauthz_callback']);
  }


  toggleMenu() {
    if (this.settingsService.menuToggle$.value == true) {
      this.settingsService.menuToggle$.next(false);
      return
    }
    this.settingsService.menuToggle$.next(true);
  }


  private displayMessage(notification: [string,any]) {
   var msg: string = notification[0]
   var fn: any = notification[1]
   if (msg === null || msg == "") {
     if (this.snackBarRef != undefined) {
       this.snackBarRef.dismiss()
     }
   } else {
     this.snackBarRef = this.snackBar.open(msg,'Dismiss');
     if (fn !== null && fn !== undefined) {
       this.snackBarRef.afterDismissed().subscribe(() => {  fn() });
     }
   }
 }

  setTheme(theme: string)  {
    if (theme == null) {
      theme = this.envLoaderService.default_theme;
    }
    let container = document.querySelector('app-root');
    let classList = container.classList;
    var c;
    for (c of classList.value.split(' ')) {
      if (c.indexOf('strudel-') == 0) {
        classList.remove(c);
      }
    }
    classList.add(theme);
  }

}
