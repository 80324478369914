<div class="other-component" fxLayout="row" fxLayoutAlign="space-around">    
<div fxLayout="column" class="content">
    <div class="heading"><h1>OUR SERVICES</h1></div>
    <mat-divider></mat-divider>
    <div>&nbsp;</div>
    <div fxLayout="row" fxLayoutAlign="start none">
        <div fxFlex="20%">
            <p>DESKTOP</p>
        </div>
        <div fxFlex="10%">
            <div class="vertical-line"></div>
        </div>
        <div fxFlex="70%">
            <p>The MASSIVE Desktop is a remote desktop environment that allows researchers to access interactive desktop applications alongside the MASSIVE HPC environment.</p>
            <div fxLayoutAlign="end none">
                <button mat-flat-button color=primary style="width: 180px" onClick="window.open('')">Launch Desktop</button>
            </div>
        </div>
    </div>
    <div>&nbsp;</div>
    <mat-divider></mat-divider>
    <div>&nbsp;</div>
    <div fxLayout="row" fxLayoutAlign="start none">
        <div fxFlex="20%">
            <p>JUPYTER LAB</p>
        </div>
        <div fxFlex="10%">
            <div class="vertical-line"></div>
        </div>
        <div fxFlex="70%">
            <p>Jupyter Lab is a browser-based tool for interactive authoring of documents which combine explanatory text, mathematics, computations and their rich media output.</p>
            <div fxLayoutAlign="end none">
                <button mat-flat-button color=primary class="big-button" onClick="window.open('')">Launch Jupyter Lab</button>
            </div>
        </div>
    </div>
</div>
</div>
