<mat-list-item style="height: auto; width: auto" class="strudel-job-typography">
  <div  style="width: 100%; display: flex; flex-direction: column;" class="list-item">
    <div style="text-align: left"> {{ jobdata.app.name }} </div>
    
    <div *ngIf="jobdata.connectionState == 0 || jobdata.connectionState == null" style="display:flex; flex-direction: row; justify-content: space-between">
      <div  style="flex: 0; flex-basis: 10%; overflow: auto"> {{ jobdata.state }}</div>
      <div  style="flex: 0; flex-basis: 10%; overflow: auto"> {{ jobdata.jobid }}</div>
      <div *ngIf="jobdata.batch_host != 'localhost'" style="overflow: auto" fxFlex="10%"> {{ jobdata.batch_host }}</div>
      <div style="flex: 0; flex-basis: 20%; overflow: auto; justify-content: end; align-items: end;" >
        <div style="display: flex; flex-direction: column; align-items: center;">
            <div style="flex: 1"> {{ cpu_resources }} </div>
            <div style="flex: 1"> {{ mem_resources }} </div>
        </div>
      </div>
      <div style="flex: 0; flex-basis: 20%; display: flex; flex-direction: column; overflow: auto">
        <div> {{ timeremaining }} </div>
      </div>
      <div style="flex: 1"></div>
      
      <div style="display: flex; flex-direction: row">
        <div style="flex: 1" *ngIf="!nocancel"> <button mat-button  (click)="onCancel()" [disabled]="jobdata.connectionState == null"> Cancel </button> </div>
        <div style="flex: 1" *ngFor="let action of jobdata.app.instactions">
            <!--<div fxFlex *ngIf="action.states === undefined || action.states === null || action.states.indexOf(jobdata.state) != -1 && (action.localapi != true || backendService.localapi == true) ">-->
            <div *ngIf="(action.states === undefined || action.states === null || action.states.indexOf(jobdata.state) != -1)  && ((ipcService.useIpc) == true || action.localapi != true)">
                <button mat-button (click)="onConnect(action)" [disabled]="jobdata.connectionState == null">{{ action.name }}</button>
            </div>
        </div>
      </div>

    </div>

    <div fxLayout="row" *ngIf="jobdata.connectionState == 1">
      <div fxFlex="20%"> Getting app parameters </div>
      <div fxFlex="80%"> <mat-progress-bar mode="determinate" value=30></mat-progress-bar> </div>
    </div>
     
    <div fxLayout="row" *ngIf="jobdata.connectionState == 2">
        <div fxFlex="20%"> Creating secure tunnels </div>
        <div fxFlex="80%"> <mat-progress-bar mode="determinate" value=60></mat-progress-bar> </div>
    </div>
     
    <div fxLayout="row" *ngIf="jobdata.connectionState == 3">
      <div fxFlex="20%"> Determining correct URL </div>
      <div fxFlex="80%"> <mat-progress-bar mode="determinate" value=90></mat-progress-bar> </div>
    </div>
  </div>
</mat-list-item>

<!--<mat-list-item>
    <div fxLayout="column" fxLayoutAlign="space-around none" style="width: 100%; background-color: red">
        <div fxLayout="row" style="background-color: green">
            <div>
                {{ jobdata.name }}
            </div>
            <div fxFlex>
            </div>
        </div>
        <div *ngIf="jobdata.connectionState == 0" fxLayout="row" style="background-color: blue">
            <div>
               {{ jobdata.state }}
            </div>
            <div></div>
            <div fxLayout="column">
              <div> {{ resources }} </div>
              <div> {{ timeremaining }} </div>
            </div>
        </div>
    </div>
</mat-list-item>-->
