
import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-warndialog',
  templateUrl: './warndialog.component.html',
  styleUrls: ['./warndialog.component.css']
})
export class WarndialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WarndialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
) {
   }

  ngOnInit() {
  }

  close(rv) {
    this.dialogRef.close(rv);
  }

}
