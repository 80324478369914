import {Computesite} from './computesite';
import {Identity} from './identity';
import {Strudelapp, StrudelappInstance } from './strudelapp';

export class Job {
  public name: string;
  public appname: string;
  public jobid: string;
  public desc: string;
  public state: string;
  public endtime: string;
  public starttime: string;
  public mem: string;
  public cpus: string;
  public batch_host: string;
  public identity: Identity;
  public app: Strudelapp;
  public appinst: any;
  public connectionState: number;

}
