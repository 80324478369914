// This is the main login screen. It displays of dropdown list of places where you can get an SSH certificate

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import {OverlayContainer} from '@angular/cdk/overlay';
import { TesService } from '../tes.service';
import {BackendSelectionService } from '../backend-selection.service';
import { AuthorisationService } from '../authorisation.service';
import { Identity } from '../identity';
import { ComputesitesService } from '../computesites.service';
import {DomSanitizer} from '@angular/platform-browser';
import {PipeTransform, Pipe} from "@angular/core";
import { LocationStrategy } from '@angular/common';
import { AsyncHook } from 'async_hooks';
import { OAuthService, OAuthSuccessEvent, AuthConfig } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs-compat';
import { shareReplay, takeUntil, tap, filter } from 'rxjs/operators';
import { SshAuthzServer } from '../identity';
import { Router } from '@angular/router';

// This allows us to include html links in the description of each site you can log into
@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public authorised: boolean;
  public identity: Identity;
  public identities: Identity[];
  public selectedApiServer: any;
  public selected: any;
  public loggedInAuthZ: any;
  public loggedOutAuthZ: any;


  constructor( public dialog: MatDialog,
                public tesService: TesService,
                public backendSelectionService: BackendSelectionService,
                public authService: AuthorisationService,
                public computeSitesService: ComputesitesService,
                public overlayContainer: OverlayContainer,
                private locationStrategy: LocationStrategy,
                private oauthService: OAuthService,
                private router: Router
                ) {

  }

  ngOnInit() {
    //this.subscriptions.push(this.computeSitesService.appidentities.subscribe(o => this.navLaunch(o)));
/*     let navlaunch = localStorage.getItem('strudel-navlaunch')
    //const isdefined = filter(v  => v !== null && v !== undefined)

    if (navlaunch == 'true') {
      localStorage.setItem('strudel-navlaunch',null)
      //this.subscriptions.push(this.computeSitesService.appidentities.subscribe(o => this.navLaunch(o)));
    }
 */

    this.authService.loggedInAuthZ$.subscribe((v) => { this.loggedInAuthZ = v})
    this.authService.loggedOutAuthZ$.subscribe((v) => { this.loggedOutAuthZ = v})
    

    let newIdTokenPipe = this.oauthService.events.pipe(
      filter((e) => e.type === 'token_received'),
      tap((v) => console.log('newIDTokenPipe',v)),
      //takeUntil(this.canceled$),
      shareReplay()
    )
    newIdTokenPipe.subscribe((v) => this.process_id_token(v))
    this.authService.loggedOutAuthZ$.subscribe((v) => this.navLaunch());

  }


  process_id_token(v) {
    console.log('processingIdToken');
    console.log(this.idToken);
  }


  public get name() {
    let claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims.given_name;
  }

  ngOnDestroy() {

  }

  navLaunch() {
    if (this.authService.loggedInAuthZ$.value.length > 0 && this.authService.loggedOutAuthZ$.value.length == 0) {
      this.router.navigate(['/launch']);
    } 
  }

/*   navLaunch(o) {
    this.computeSitesService.appidentities$.pipe(filter((v) => v.length >= 0)).subscribe((v) => this.router.navigate['/launch'])
     if (this.computeSitesService.appidentities.value !== null && this.computeSitesService.appidentities.value.length > 0) {
      this.router.navigate(['/launch']);
    } 
  }
 */

//  login () {
    //localStorage.setItem('strudel-navlaunch','true');
    //this.authService.login(this.selected);
//  }

  public login_oidc(authservice: SshAuthzServer) {
    window.sessionStorage.setItem('oidc_authserver',JSON.stringify(authservice))
    this.oauthService.configure(authservice);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();


    console.error('login_oidc',authservice);
    if (this.idToken) {
      try {
      console.log('not doing this.oauthService.revokeTokenAndLogout()');
      } catch {}
      try {
        this.oauthService.logOut(true);
      } catch {}
    }
    console.log('initLoginFlow');

    this.oauthService.initLoginFlow();


  }

  get idToken(): string {
    return this.oauthService.getIdToken();
  }

  public login() {
    let authservice = this.selected as SshAuthzServer;
    console.log('issuer',authservice.issuer);
    if (authservice.issuer) {
      console.log('logging in with oidc');
      this.login_oidc(authservice)
    }
    else {
      this.login_code(authservice);
    }
  }

  public login_code(authservice: SshAuthzServer) {
    // Initiate Login
    console.error('login_code',authservice);
    let redirect_uri = window.location.origin+this.locationStrategy.getBaseHref()+"sshauthz_callback";
    if (redirect_uri.includes("file:///")) {
      redirect_uri = "http://localhost:4200/sshauthz_callback";
    }
    let nonce=Math.random().toString(36).substring(2, 15)
  
    sessionStorage.setItem('authservice', JSON.stringify([authservice,nonce]));
    console.log('setting window location');
    sessionStorage.setItem('path', '/launch');
    if (authservice.scope == null) {
      window.location.assign(authservice.authorise+"?response_type=token&redirect_uri="+redirect_uri+"&state="+nonce+"&client_id="+authservice.client_id);
    } else {
      window.location.assign(authservice.authorise+"?response_type=token&redirect_uri="+redirect_uri+"&state="+nonce+"&client_id="+authservice.client_id+"&scope="+authservice.scope);
    }
  }

}

