import { Component, EventEmitter, Input, Output } from '@angular/core';
import { angularMaterialRenderers } from '@jsonforms/angular-material';
import { and, or, createAjv, isControl, optionIs, rankWith, schemaTypeIs, scopeEndsWith, Tester } from '@jsonforms/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap} from 'rxjs/operators';
import { TesService } from '../tes.service';
import { Strudelapp, AppAction } from '../strudelapp';
import { Job } from '../job';
import { BatchInterface } from '../batchinterface';
import { Identity } from '../identity';
import { HttpErrorResponse } from '@angular/common/http';
import { DurationComponent } from '../duration/duration.component';
import { JsonformsEnumRenderComponent } from '../jsonforms-enum-render/jsonforms-enum-render.component';


const durationTester: Tester = scopeEndsWith('duration');

const enumTester: Tester = or(
  scopeEndsWith('c7config'),
  scopeEndsWith('r9config')
)

@Component({
  selector: 'app-launchform',
  templateUrl: './launchform.component.html',
  styleUrls: ['./launchform.component.css']
})
export class LaunchformComponent {
  renderers = [
    ...angularMaterialRenderers,
   { tester: rankWith(5, durationTester), renderer: DurationComponent },
   { tester: rankWith(5, enumTester), renderer: JsonformsEnumRenderComponent },
   { tester: rankWith(4, scopeEndsWith('service')), renderer: JsonformsEnumRenderComponent}

    
  ];
  @Input() app: Strudelapp;
  @Input() identity;
  
  data: any = {};
  emitData: EventEmitter<any> = new EventEmitter<any>();
  schema: any = {};
  uischema: any = {};

  schemaObs$: Observable<any>;



  uiSchemaObs: Observable<any>;
  dataSchemaObs: Observable<any>;
  //data = dataAsset;
  i18n = {locale: 'en-AU'}
  dateAdapter;
  ajv = createAjv({
    schemaId: 'id',
    allErrors: true
  });

  

  constructor(private tes: TesService) {

  }

  private handleLaunchFormError(e: HttpErrorResponse): Observable<any> {
    const errordataschema: any = {
      "properties": {
          "msg": {
              "default": "Something went wrong",
              "title": "Msg",
              "type": "string"
          }
      },
      "title": "ErrorMsg",
      "type": "object"
    };
    const erroruischema: any = {
      "type": "Control",
      "scope": "#"
    };
    if (e.status == 500) {
      return of({'schema': errordataschema, 'uischema': erroruischema, 'data': {'msg': e.message}})
    }
    throwError(e)
  }

  ngOnInit(): void {
  
    this.schemaObs$ = this.tes.runCommand(this.identity, this.app.submitcmd, {'input': ""}).pipe(
      catchError((e) => this.handleLaunchFormError(e)),
      tap((v) => { this.schema = v.schema ; this.uischema = v.uischema; this.data = v.data }),
    )
  }
  ngOnChanges(): void {
    this.schemaObs$ = this.tes.runCommand(this.identity, this.app.submitcmd, {'input': ""}).pipe(
      tap((v) => { this.schema = v.schema ; this.uischema = v.uischema; this.data = v.data }),
    )
  }

  runaction(action: AppAction) {
    var job: Job;
    var newaction: AppAction;
    job =  new Job();
    job.identity = this.identity;
    job.batch_host = 'localhost';
    job.jobid='1';
    newaction = new AppAction();
    newaction.paramscmd = action.paramscmd;
    newaction.notunnel = action.notunnel;
    newaction.client = action.client;
    newaction.postData = action.postData;
    this.tes.connect(job,newaction);
  }

  launch() {
    // tell the batchbuilder iframe that lanch was selected
    // This is the cue to save the values as defaults for next launch

    let bi = new BatchInterface();
    bi.submitcmd = this.app.submitcmd;
    let id: Identity = this.identity.copy_skip_catalog() // we should nullify the submitcmdprefix as this is only used with the old iframe method
    id.site.submitcmdprefix = null;
    let app = new Strudelapp()
    app =  JSON.parse(JSON.stringify(this.app))
    let submitparams = JSON.parse(JSON.stringify(this.data))
    submitparams['startscript'] = this.app.startscript;
    submitparams['name'] = this.app.name;
    app.startscript = btoa(JSON.stringify(submitparams))

    let appparams = this.data;
    this.tes.submit(app,id,bi);

  }
}
