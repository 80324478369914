// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  contact: ["<STRUDEL Developers> chris.hines@monash.edu, jafar.lie@monash.edu, lance.wilson@monash.edu"],
  logserver: "https://strudel2-api-dev.cloud.cvl.org.au/logging",
  loginterval: 10000,
  name: "STRUDEL",
  about_slides: "https://docs.google.com/presentation/d/1msXPRGlAjXeRemfM92RKmvLDr-GB3FXWDwIdAgIWFjI/embed",
  default_theme: "strudel-light-theme"
};
