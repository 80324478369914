import { Component, OnInit, Input } from '@angular/core';
import { Identity } from '../identity';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TesService } from '../tes.service';
import { BehaviorSubject, Subscription  } from 'rxjs';
import { filter  } from 'rxjs/operators';
import {Strudelapp} from '../strudelapp';
import {Health} from '../computesite';

@Component({
  selector: 'app-accountinfo',
  templateUrl: './accountinfo.component.html',
  styleUrls: ['./accountinfo.component.css']
})
export class AccountinfoComponent implements OnInit {
  @Input() identity: Identity;
  private subscriptions: Subscription[];

  constructor(
                private router: Router,
                public tesService: TesService,
  ) { 
    this.subscriptions = [];

  }

  ngOnInit() {
    //this.identity.accountalerts.subscribe((v) => {console.log('account alerts',v)});
  }

  cssClass(h) {
    if (h.stat == 'error')
      return 'health-error';
    if (h.stat == 'warn')
      return 'health-warn';
    return 'health-ok';
  }

  save_file(fileName,data){
    if (data !== null) {
      const file = new Blob([data], {type: "text/plain"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      if (fileName !== null) {
        link.download = fileName;
      } else {
        link.download='downloadfile'
      }
      link.click();
      link.remove();
    }
  }

  download_key() {
    let keys = JSON.parse(sessionStorage.getItem('keys'));
    this.save_file('strudel2_ssh_key', keys[0].key);
    this.save_file('strudel2_ssh_key-cert.pub', keys[0].cert);

  }



  calculateCols(data) {
    return data.map(function(r) { return r.key });
  }

  humanKBytes(n: number) {
    if (n > 1024*1024*1024) {
      let v = n/1024/1024/1024;
      return v.toFixed(0)+'TB';
    }
    if (n > 1024*1024) {
      let v = n/1024/1024;
      return v.toFixed(0)+'GB';
    }
    if (n > 1024) {
      let v = n/1024;
      return v.toFixed(0)+'MB';
    }
    return '0 MB';
  }

  navLogin(o) {
    if (o == null) {
      return
    }
    if (o.length == 0) {
      this.router.navigate(['/login']);
    } else {
    }
  }

  nowSeconds(id: Identity) {
    let seconds = (id.expiry - Date.now().valueOf())/1000;
    let remaining = this.secondsToHms(seconds);
    return remaining;
  }
  secondsToHms(d: number) {
    var sign = ""
    if (d < 0) {
      d = -d;
      sign="-"
    }
    
    var h: number = Math.floor(d / 3600);
    var m: number = Math.floor(d % 3600 / 60);
    var s: number = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    return sign + hDisplay + mDisplay ; 
  }

}
